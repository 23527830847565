import React from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledPaper = styled("div")(({ theme, width, padding }) => ({
  backgroundColor: theme.palette.background.paper,
  border: "0",
  boxShadow: theme.shadows[5],
  padding: padding || theme.spacing(2, 4, 3),
  maxWidth: width || 500,
  width: "95%",
  maxHeight: "80vh",
  overflowY: "auto",
  position: "relative", // Add this to make positioning easier
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const GlobalModal = (params) => {


  return (
    <StyledModal open={params.open} onClose={params.onClose} className={params.className}>
      <StyledPaper width={params.width} padding={params.padding}>
        <CloseButton onClick={params.onClose}>
          <CloseIcon />
        </CloseButton>
        {params.children}
      </StyledPaper>
    </StyledModal>
  );
};

export default GlobalModal;
