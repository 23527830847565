import { HasPermission } from "../../../helper/helperFunction";
import { Link } from "react-router-dom";
import FilterBase from "../../../components/filterJob/filter-base1";
import CSVDownloadButton from "../../../components/downloadCSV";
import styles from "./style.module.css";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { useState, useEffect, useCallback } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";

import {
  Box,
  Typography,
  Select,
  MenuItem,
  Modal,
  Button,
} from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Moment from "react-moment";
import ImageView from "../../../components/imageShow";
import Tooltip from "@mui/material/Tooltip";
import EditSkills from "../../student/manage-skills/edit-skills";
import ActionButtons from "../../../components/dataTables/ActionButtons";
import { downloadFile } from "../../../helper/helperFunction";

export const StudentResume = () => {
  const [filters, setFilters] = useState();
  const [currentValue, setCurrentValue] = useState("desc");
  const [editId, setEditId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setEditId(null);
  };

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  const handleDownload = (filePathParam, fileNameParam) => {
    const filePath = filePathParam;
    const fileName = fileNameParam;

    try {
      downloadFile(filePath, fileName);
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
      width: "80px",
    },
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.student_name} />
          {toTitleCase(`${row.student_name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      width: "200px",
      selector: "student_name",
    },
    {
      cell: (row) => (
        <Tooltip title={row.student_email} arrow>
          {row.student_email}
        </Tooltip>
      ),
      name: "Email",
      sortable: true,
      width: "220px",
      selector: "student_name",
    },
    {
      cell: (row) => row.location,
      name: "Location",
      sortable: true,
      width: "150px",
    },
    {
      cell: (row) => {
        const skills = row.skills.split(",").map((item) => item.trim());
        const firstThreeSkills = skills.slice(0, 3);
        const remainingSkills = skills.slice(3);

        return (
          <Tooltip title={remainingSkills.join(", ")} arrow>
            <span>
              {firstThreeSkills.join(", ")}
              {remainingSkills.length > 0 && "..."}
            </span>
          </Tooltip>
        );
      },
      selector: "skills",
      sortable: true,
      name: "Skills",
      width: "150px",
    },
    {
      cell: (row) => {
        const skills = row.other_skills.split(",").map((item) => item.trim());
        const firstThreeSkills = skills.slice(0, 3);
        const remainingSkills = skills.slice(3);

        return (
          <Tooltip title={remainingSkills.join(", ")} arrow>
            <span>
              {firstThreeSkills.join(", ")}
              {remainingSkills.length > 0 && "..."}
            </span>
          </Tooltip>
        );
      },
      selector: "skills",
      sortable: true,
      name: "Other Skills",
      width: "150px",
    },

    {
      cell: (row) => row.job_type,
      name: "Job Type",
      sortable: true,
      width: "150px",
    },

    {
      cell: (row) => <Moment format="DD/MM/YYYY">{row.updated_on}</Moment>,
      selector: "updated_on",
      name: "Updated on",
      width: "150px",
      sortable: true,
    },

    // {
    //   selector: (row) => (
    //     <Typography
    //       variant="body2"
    //       sx={{
    //         borderRadius: "5px",
    //         background: "#001C28",
    //         color: "#FFF",
    //         padding: "5px 10px",
    //         opacity: "0.5",
    //       }}
    //     >
    //       Pre Assessment Form
    //     </Typography>
    //   ),
    //   name: "Pre Assessment Form",
    //   width: "200px",
    //   sortable: false,
    // },
    {
      name: "Actions",
      width: "120px",
      cell: (row) => (
        <>
          <ActionButtons
            id={row.id}
            onEdit={onEdit}
            onDelete={onDelete}
            refreshHandle={refresh}
            actions={{
              view: false,
            }}
            module="manage-student-resume"
          />
          <a href={`${SERVER_KEY}${row.resume}`} target="_blank">
            <Button
              className="view resume"
              // onClick={() => {
              //   handleDownload(`${SERVER_KEY}${row.resume}`, "resume");
              // }}
            >
              resume
            </Button>
          </a>
        </>
      ),
    },
  ];

  const onEdit = async (id) => {
    setEditId(id);
    setIsModalOpen(true);
  };
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.STUDENT_RESUMES_DEL, id));
  };
  const [success, setSuccess] = useState();
  const [reRender, setReRender] = useState();
  useEffect(() => {
    if (success) {
      setReRender((prevState) => !prevState);
      setEditId(null);
      toggleModal();
    }
  }, [success]);

  const refresh = () => {
    setReRender((prevState) => !prevState);
  };
  const [reportData, setReportData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(AdminApi.STUDENT_RESUMES, {
        params: {
          sort_order: currentValue,
          download_csv:true

        },
      });

      const data = response.data.data ? response.data.data : [];

      const modifiedData = data.map((item) => {
        const { profile_image, resume, ...rest } = item;

        const Resume = `${SERVER_KEY}${resume}`;

        return { ...rest, Resume };
      });

      setReportData(modifiedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [currentValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <Box className="container">
        <Box className={styles.display_left}>
          <Box className="course-content">
            <h1 className="main-heading">Student Resumes </h1>
          </Box>
          <Box className={styles.display_right}>
            <Box marginRight={2}>
              <CSVDownloadButton fileName={"Resumes"} data={reportData ?? []} />
            </Box>
            <Box>
              <Select
                value={currentValue}
                onChange={handleChange}
                className="select-menu"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        <Box className={styles.display_left}>
          <FilterBase onFiltersChange={handleFiltersChange} name={"Name"} />
        </Box>
        <Box
          className={`student-resume page-container skills_popup table-phases ${styles.table_wrap}`}
          marginTop={5}
        >
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.STUDENT_RESUMES}
            onEdit={onEdit}
            onDelete={onDelete}
            actions={{
              view: false,
            }}
            refreshHandle={reRender}
            filters={filters}
            sortOrder={currentValue}
            actionColumns={false}
            module="manage-student-resume"
          />
        </Box>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        className={styles.apply_job}
      >
        <Box className={styles.popup + " " + "skill"} padding={6}>
          <button
            onClick={toggleModal}
            style={{ opacity: 0 }}
            className="close_btn"
          >
            Close
          </button>
          <EditSkills setSuccess={setSuccess} id={editId} />
        </Box>
      </Modal>
    </>
  );
};
