import { Typography } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImageView from "../../../components/imageShow";
import Moment from "react-moment";
import { EditWorkShop } from "./edit";
import styles from "./style.module.css";
import { api, resolveUrl } from "../../../config/apis";

export const WorkShopGrid = (props) => {
  const navigate = useNavigate();

  const tableColumns = [
    {
      selector: (row) => (
        <>
          <Typography variant="body2" fontWeight={"700"}>
            {row.title}
          </Typography>
        </>
      ),
      selector: "title",
      name: "Workshop Title",
      sortable: true,
      minWidth: "300px",
    },
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.teacher} />
          {
            <Typography variant="body2">
              {toTitleCase(`${row.teacher ?? ""}`)}
            </Typography>
          }
        </>
      ),
      selector: "teacher",
      name: "Assigned Teacher",
      sortable: true,
      minWidth: "250px",
    },
    {
      selector: (row) => (
        <>
          <Typography variant="body2" fontWeight={"700"}>
            {row.location}
          </Typography>
        </>
      ),
      selector: "location",
      name: "Location",
      sortable: true,
      minWidth: "120px",
    },
    {
      selector: (row) => (
        <>
          {row.workshop_sections.map((item, index) => {
            return (
              <>
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ display: "inline-block" }}
                >
                  {item.cohort_name}
                  {index < row.workshop_sections.length - 1 && <span>, </span>}
                </Typography>
              </>
            );
          })}
        </>
      ),
      name: "Cohort(s)",
      sortable: false,
      minWidth: "350px",
    },
    {
      selector: (row) => (
        <>
          {row.workshop_sections.map((item) => {
            return item.sections.map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ display: "inline-block" }}
                >
                  {item.section_name}{" "}
                  {item.course_name && `(${item.section_name})`}
                </Typography>
              );
            });
          })}
        </>
      ),
      name: "Section(s)",
      sortable: false,
      minWidth: "400px",
    },
    {
      selector: (row) => (
        <Typography variant="body2">{row.duration}</Typography>
      ),
      name: "Duration",
      sortable: true,
      minWidth: "150px",
    },
    {
      selector: (row) => (
        <>
          <Typography variant="body2">
            <Moment format="DD/MM/YYYY ">{row.date}</Moment>, {row.time}
          </Typography>
        </>
      ),
      selector: "date",
      name: "Date / Time",
      minWidth: "150px",
      sortable: true,
    },

    {
      cell: (row) => (
        <Typography variant="body2" fontWeight={"700"}>
          {row.student}
        </Typography>
      ),
      selector: "student",
      name: "Students",
      minWidth: "150px",
      sortable: true,
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/work-shop/View/${id}`);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    setReRender(formSuccess);
    setModalOpen(false);
  }, [formSuccess]);

  useEffect(() => {
    if (props.modalOpen) {
      setModalOpen(props.modalOpen);
    }
  }, [props.modalOpen]);

  useEffect(() => {
    if (props.setModalOpen) {
      if (!modalOpen) {
        props.setModalOpen(false);
      }
    }
  }, [modalOpen]);
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.WORKSHOP_DELETE, id));
  };
  return (
    <>
      <div
        className={`page-container table-phases ${styles.WORKSHOP_PAGINATION}`}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.WORKSHOP_PAGINATION}
          onView={onView}
          onDelete={onDelete}
          filters={props.filters ?? ""}
          sortOrder={props.currentValue}
          actions={{
            view: true,
            edit: false,
          }}
          module="manage-work-shop"
          refreshHandle={reRender}
        />
      </div>
      <EditWorkShop
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setModalOpen={setModalOpen}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
};
