import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Chip, Popover } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import GlobalModal from "../../../components/modalBox";
import styles from "./manage-support-ticket.module.css";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import { IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SettingsIcon from '@mui/icons-material/Settings';
import { TicketComment } from "./ticket-comment";
import { TicketAttachment } from "./ticket-attachment";
import { toTitleCase } from "../../../helper/helperFunction";

export const ViewTicket = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [viewData, setViewData] = useState(null);

  const handleCloseModal = () => {
    props.setModalOpen(false);
    queryParams.delete("id");
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
    setViewData(null);
  };
  const handleCloseCommentModal = () => {
    setCommentModalOpen(false);
  };
  const handleCloseAttachmentModal = () => {
    setAttachmentModalOpen(false);
  };




  const [commentModalOpen, setCommentModalOpen] = useState(false); 
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false); 
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleFilterClick = (event) => {
    event.stopPropagation();  // Prevent parent from blocking the click
    setAnchorEl(event.currentTarget);
  };
  
  const handleFilterClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [selectedItem, setSelectedItem] = useState({
    history:null,
  });

  
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    if (props.viewId && selectedItem) {
      const fetchEditData = async () => {
        try {
          const params = {};
          if (selectedItem === 'Comments') {
            params.comments = true;
          } else if (selectedItem === 'Files') {
            params.attachment = true; 
          }
           else if (selectedItem === 'History') {
            params.history = true;
          }

          const response = await api.get(
            resolveUrl(AdminApi.SUPPORT_TICKET_VIEW_DETAIL, props.viewId),
            { params }
          );
          setViewData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchEditData();
    }
  }, [props.modalOpen, props.viewId, selectedItem, formSuccess]);



  useEffect(() => {
      setCommentModalOpen(false);
      setAttachmentModalOpen(false);
  }, [formSuccess]);  

  return (
    <>
      <GlobalModal padding={"0"} open={props.modalOpen} onClose={handleCloseModal} width={960} className={styles.modalContainer}>
          {viewData && (
            <>
            <h2>{viewData?.title}</h2>
            <div className={styles.modalBody}>
            <div className={styles.historyCol}>
                <div className={styles.description}>
                <h6 className={styles.modalTitle}>Description</h6>
                <p
                  className={styles.descriptionContent}
                  dangerouslySetInnerHTML={{ __html: viewData.description }}
                />
              </div>

              {/* History */}
              <div className={styles.history}>
              <div className={styles.history_wrap}>
                <h6 className={styles.modalTitle}>History</h6>
                <div className={styles.iconButtonGroup}>
                <IconButton className={styles.iconButton} onClick={()=>setCommentModalOpen(true)}>
                  <ChatIcon className={styles.icon} />
                </IconButton>
                <IconButton className={styles.iconButton} onClick={()=>setAttachmentModalOpen(true)}>
                  <AttachFileIcon className={styles.icon} />
                </IconButton>
                <IconButton className={styles.iconButton} aria-describedby={id} onClick={handleFilterClick}>
                  <SettingsIcon className={styles.icon} />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                     <ul className={styles.Popover_list}>
                      <li onClick={() => handleItemClick('History')}>History</li>
                      <li onClick={() => handleItemClick('Comments')}>Comments</li>
                      <li onClick={() => handleItemClick('Files')}>Files</li>
                    </ul>
                  </Popover>

                </IconButton>
              </div>
              </div>
                {viewData.history.map((item, index) => (
                  <div key={index} className={styles.historyItem}>
                    <h6 className={styles.historyTitle}>{`${item.created_date} - ${item.assigned_by}`} <span className={styles.id}>{`#${item.id}`}</span></h6>
                    <ul className={styles.history_list}>
                      <li>Assigned by : {item.assigned_by}</li>
                      <li>Assigned to : {item.assigned_to}</li>
                      <li>Created Date : {item.created_date}</li>
                      <li>Status: {item.status.old && `${item.status.old} →`} {item.status.current}</li>
                    </ul>


                    {item.attachment && item.attachment.length > 0 && (
                      <div>
                    <h6 className={styles.historyTitle}>{`${item.created_date} - ${item.assigned_by}`} <span className={styles.id}>{`#${item.id}`}</span></h6>                        
                    <ul className={styles.attachmentList}>
                          {item.attachment.map((file, idx) => (
                            <li key={idx}>
                              <a href={`${SERVER_KEY}${file.url}`} target="_blank" rel="noopener noreferrer" style={{cursor:"pointer"}}> 
                              <Chip
                                  key={index}
                                  label={file.file}
                                  style={{
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                  }}/>
                                  </a>
                            </li>
                            
                          ))}
                        </ul>
                      </div>
                    )}

                    {item.comments && item.comments.length > 0 && (
                      <div className={styles.comments}>
                                            
                        {item.comments.map((comment) => (
                          <>
                    <h6 className={styles.historyTitle}>{`${comment.created_date} - ${comment.comment_by}`} <span className={styles.id}>{`#${comment.id}`}</span></h6>
                    <div key={comment.id} className={styles.comment}>
                              <div dangerouslySetInnerHTML={{ __html: comment.message }} />    
                          </div>
                        </>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            

              {/* Personal Data */}
              <div className={styles.personalData}>
                <h6 className={styles.modalTitle}>Personal Data</h6>
                <div className={styles.personalDataItem}>
                  <p><strong>Assigned to:</strong> {viewData.personal_data.assigned_to}</p>
                  <p><strong>Created Date:</strong> {viewData.personal_data.created_date}</p>
                  <p><strong>Due Date:</strong> {viewData.personal_data.due_date}</p>
                  <p><strong>Last Modified:</strong> {viewData.personal_data.last_modified}</p>
                  <p><strong>Priority:</strong> <span className={styles.priorityHigh}>{viewData.personal_data.priority && toTitleCase(viewData?.personal_data?.priority)}</span></p>
                  <p><strong>Status:</strong> <span className={styles.statusCompleted}>{viewData.personal_data.status && toTitleCase(viewData?.personal_data?.status)}</span></p>
                </div>
              </div>
            </div>
            </>
          )}
      </GlobalModal>
      <TicketComment
        modalOpen={commentModalOpen}
        handleCloseModal={handleCloseCommentModal}
        setModalOpen={setCommentModalOpen}
        ticketTitle={viewData?.title}
        setFormSuccess={setFormSuccess}
        ticketId={props.viewId}
      />
      <TicketAttachment
        modalOpen={attachmentModalOpen}
        handleCloseModal={handleCloseAttachmentModal}
        setModalOpen={setAttachmentModalOpen}
        ticketTitle={viewData?.title}
        ticketId={props.viewId}
        setFormSuccess={setFormSuccess}

      />
    </>
  );
};
