

import { Grid, Box, Select, MenuItem } from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { useState } from "react";
import { Link } from "react-router-dom";
import FilterBase from "../../../components/filter/filter-base";
import CSVDownloadButton from "../../../components/downloadCSV";
import { SupportTicketsGrid } from "./SupportTable.jsx";
import { HasPermission } from "../../../helper/helperFunction";
import SelectOptionsApi from "../../../components/SelectApi/index.jsx";


export default function ViewSupportTicket() {
 
    const [filters, setFilters] = useState();
    const [location, setLocation] = useState();

    const handleFiltersChange = (filterState) => {
      setFilters({
        ...filterState,
        location:location
      });
    };
    
    const [editModalOpen, setEditModalOpen] = useState(false); // Separate state for edit modal
    
    const handleUpdate = (name, value) => {    
      setFilters({
        location:value
      });
      setLocation(value);
    };
    
    return (
      <>
        <div className="container transaction_module">
          <div className="course-content">
            <h1 className="main-heading"> Support Tickets </h1>
            <HasPermission permission="manage-support-ticket.create">
              <Box className="btn-wrapper">
                <Link
                  to={"#"}
                  onClick={() => {
                    setEditModalOpen(true);
                  }}
                >
                  Add Tickets
                </Link>
              </Box>
            </HasPermission>
          </div>
  
          <Grid container marginBottom={2}>
            <Grid item xl={8} xs={12}>
              <FilterBase onFiltersChange={handleFiltersChange} />
            </Grid>
            <Grid
              item
              xl={4}
              xs={12}
              marginTop={{ xl: 0, xs: 2 }}
              display="flex"
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={6}
                sx={{
                  paddingLeft:"10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <CSVDownloadButton
                  apiEndpoint={AdminApi.SUPPORT_TICKET_LIST}
                  params={"desc"}
                  fileName={"Tickets"}
                />
           
                     <SelectOptionsApi
                        apiEndpoint={AdminApi.LOCATION_PAGINATE}
                        formData={filters}
                        onUpdate={handleUpdate}
                        labelKey="name"
                        name="location"
                        valueKey="id"
                        errorText=""
                        paramId={""}
                        defaultText="Select location"
                      />
              </Grid>
            </Grid>
          </Grid>
  
          <SupportTicketsGrid
            filters={filters}
            currentValue={"desc"}
            editModalOpen={editModalOpen}
            setEditModalOpen={setEditModalOpen}
          />
        </div>
      </>
    );
  }