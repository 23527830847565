import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="page-container">
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DynamicTabs(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(props.setTabsCount){
      props.setTabsCount(props.tabs[newValue].key);
    }
  };

  if(props.setTabsCount){
    props.setTabsCount(props.tabs[value].key);
  }

  return (
    <Box className="table-container" sx={{ width: '100%' }}>
      <Box className="tab" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs  variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="dynamic tabs">
          {props.tabs.map((tabData, index) => (
            <Tab key={index} label={tabData.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {props.tabs.map((tabData, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tabData.content}
        </TabPanel>
      ))}
    </Box>
  );
}
