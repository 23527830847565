import { useEffect } from "react";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import React, { useState } from "react";
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Checkbox,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import styles from "./style.module.css";
import ImageView from "../../../components/imageShow";
import Moment from "react-moment";
import { limitStringLength } from "../../../helper/helperFunction";
import Tooltip from "@mui/material/Tooltip";

export const EditWorkshopAttend = (props) => {
  const params = useParams();

  const [fetchData, setFetchData] = useState();
  const [studentStatuses, setStudentStatuses] = useState();

  useEffect(() => {
    if (params.id) {
      const fetchEditData = async () => {
        try {
          const response = await api.get(
            resolveUrl(AdminApi.WORKSHOP_ATTENDENCE_VIEW, params.id),
            {
              params: {
                date: props.date,
              },
            }
          );
          setFetchData(response.data.data);
          const studentStatus =
            response &&
            response.data.data.attendance.map((student) => ({
              student_id: student.student_id,
              status: student.status,
              profile_image: student.profile_image,
              student_name: student.student_name,
              section_id: student.section_id,
            }));

          setStudentStatuses(studentStatus);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchEditData();
    }
  }, [params.id, props.date]);

  const handleStatusChange = (studentId, newStatus) => {
    const updatedStatuses =
      studentStatuses &&
      studentStatuses.map((student) =>
        student.student_id === studentId
          ? { ...student, status: newStatus }
          : student
      );
    setStudentStatuses(updatedStatuses);
  };
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    const updatedAttendance = studentStatuses.map((student) => {
      return { ...student, status: isChecked ? "P" : "A" };
    });
    setStudentStatuses(updatedAttendance);
  };

  const updatedData = {
    date: props.date ?? "",
    attendance: studentStatuses,
  };

  return (
    <>
      <GlobalModal
        open={props.attendModalOpen}
        onClose={props.handleCloseModal}
        width={850}
      >
        <Form
          successPath={`/admin/work-shop/View/${params.id}`}
          submitUrl={AdminApi.WORKSHOP_ATTENDENCE_UPADATE}
          formData={updatedData}
          setFormSuccess={props.setFormSuccess}
          editId={params.id}
          saveText="Update"
          customClass={styles.workshop_attend_form}
        >
          <Box className={styles.workshop_attend_wrap}>
            <Typography variant="body" className={styles.date}>
              Date
              <span>
                <Moment format="DD/MM/YYYY">
                  {props.date && props.date}
                </Moment>{" "}
                {props.date && props.time}
              </span>
            </Typography>
            <TableContainer component={Paper} className={styles.attend_table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Cohort</TableCell>
                    <TableCell>Section</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>
                      <Grid item xs={3} display={"flex"} alignItems={"center"}>
                        Present All
                        <Checkbox
                          onChange={(e) => handleCheckAllChange(e)}
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "white",
                            },
                          }}
                        />
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchData &&
                    fetchData.attendance.map((student) => (
                      <TableRow key={student.student_id}>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ImageView
                              width="34px"
                              height="34px"
                              marginRight="10px"
                              imageName={student.profile_image}
                              name={student.student_name}
                            />
                            {student.student_name}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={student.cohort_name} arrow>
                            <span>
                              {student && student.cohort_name
                                ? limitStringLength(student.cohort_name, 20)
                                : "-"}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={student.section_name} arrow>
                            <span>
                              {student && student.section_name
                                ? limitStringLength(student.section_name, 20)
                                : "-"}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={styles.select_box_wrap}>
                          <Select
                            value={
                              studentStatuses.find(
                                (s) => s.student_id === student.student_id
                              ).status
                            }
                            onChange={(e) =>
                              handleStatusChange(
                                student.student_id,
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="P">P</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell
                          className={styles.select_box_wrap}
                        ></TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Form>
      </GlobalModal>
    </>
  );
};
