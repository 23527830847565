import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HasPermission } from "../../../helper/helperFunction";
import { useAuth } from "../../../hooks/auth/auth";
import { getMenuItems } from "./Menu-item";

const Menus = () => {
  const { state } = useAuth();
  const menuItem = getMenuItems(state);

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [openMenus, setOpenMenus] = useState([]);

  const toggleSubMenu = (menuIndex) => {
    if (openMenus.includes(menuIndex)) {
      setOpenMenus(openMenus.filter((index) => index !== menuIndex));
    } else {
      setOpenMenus([menuIndex]);
    }
    setCurrentPath();
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Box className="sidebar-menu">
        <ul className="menu-wrap">
          {menuItem &&
            menuItem.map((menuItem, index) => (
              <HasPermission key={index} permission={menuItem.permission}>
                {menuItem.subMenu ? (
                  <li
                    key={index}
                    className={`${menuItem.className} ${menuItem.icon} ${
                      openMenus.includes(index) ? "active" : ""
                    }`}
                  >
                    <span
                      className={`users-item`}
                      onClick={() => toggleSubMenu(index)}
                    >
                      <Link to={"#"}>{menuItem.label}</Link>
                    </span>
                    {openMenus.includes(index) && (
                      <ul className="sub-menu">
                        {menuItem.subMenu.map((subMenuItem, subIndex) => (
                          <HasPermission
                            key={subIndex}
                            permission={subMenuItem.permission}
                          >
                            <li key={subIndex}>
                              {menuItem.label === "Old Records" ?
                                  <a href={subMenuItem.path}>
                                    {subMenuItem.label}
                                  </a> :
                                  <Link to={subMenuItem.path}>
                                    {subMenuItem.label}
                                </Link>
                              }
                            </li>
                          </HasPermission>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : (
                  <li
                    onClick={() => setOpenMenus([])}
                    key={index}
                    className={`${menuItem.icon} ${menuItem.className} ${
                      currentPath === menuItem.path ? "active" : ""
                    }`}
                  >
                    <Link to={menuItem.path}>{menuItem.label}</Link>
                  </li>
                )}
              </HasPermission>
            ))}
        </ul>
      </Box>
    </>
  );
};

export default Menus;
