import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  MenuItem,
  Select,
  Chip,
  Button,
} from "@mui/material";
import { useEffect } from "react";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";
import styles from "./manage-support-ticket.module.css";
import CKEditorComponent from "../../../components/CKEditorComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateYYMMDD } from "../../../helper/helperFunction";
import { useLocation, useNavigate } from "react-router-dom";
import UploadIcon from '@mui/icons-material/Upload';
import SelectOptionsApiAuto from "../../../components/selectAutocomplete";


export const EditSupportTicket = (props) => {
  

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const priority = [
    { value: 'high', label: 'High' },
    { value: 'meduim', label: 'Meduim' },
    { value: 'low', label: 'Low' }
  ]

  const status = [
    { value: 'active', label: 'Active' },
    { value: 'completed', label: 'Completed' },
    { value: 'closed', label: 'Closed' }
  ]

  const defaultFormData = {
    title: "",
    assign_to_user_id: null,
    due_date: "",
    priority: "meduim",
    status: "active",
    description: "",
    attachments: [],
    delete_id:[]
  };

  const [formData, setFormData] = useState(defaultFormData);
  const handleCloseModal = () => {
    props.setModalOpen(false);
    props.setViewId("");
    queryParams.delete('id');
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(), 
    });
    setErrors({});
    setFormData(defaultFormData);
  };

  const [errors, setErrors] = useState({});
  

  const schema = yup.object().shape({
    title: yup.string().required("This field required"),
    assign_to_user_id: yup.string().required("This field required"),
    priority: yup.string().required("This field required"),
    due_date: yup.string().required("This field required"),
    status: yup.string().required("This field required"),
    description: yup.string().required("Description is required"),
  });



  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };



 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value ? String(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };



  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  

  
  const handleFileUpload = (event) => {
    const files = event.target.files;

    const updatedMediaData = {
      ...formData,
      attachments: [
        ...formData.attachments,
        ...Array.from(files).map((file) => ({
          file,
        })),
      ],
    };
    setFormData(updatedMediaData);
  };
  const [mediaId, setMediaId] = useState([]);

  const handleRemoveItem = (index) => {
    const updatedmediaId = mediaId;
    const removeId = updatedmediaId[index];
    updatedmediaId.splice(index, 1);

    const updatedUploadFiles = [...formData.attachments];
    updatedUploadFiles.splice(index, 1);

    const updatedMediaData = {
      ...formData,
      attachments: updatedUploadFiles,
      delete_id: [...formData.delete_id,removeId ?? null], 
    };

    setFormData(updatedMediaData);
  };




  
  useEffect(() => {
    
    if (props.editId) {
      const fetchEditData = async () => {
        try {
          const response = await api.get(
            resolveUrl(AdminApi.SUPPORT_TICKET_VIEW, props.editId)
          );
          const {assign_to_user_id ,title, due_date, status, priority, description, attachments} = response.data;
          const mediaId = [];

          if (attachments) {
            attachments.forEach((item) => {
              mediaId.push(item.id);
            });
          }
          setMediaId(mediaId);
          setFormData({
            assign_to_user_id: assign_to_user_id,
            status: status,
            title: title,
            due_date: due_date,
            priority: priority,
            description: description,
            attachments: attachments ?? [],
            delete_id:[]

          });
         
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchEditData();
    }
  }, [props.editId]);
  


  const handleEditorChange = (event, editor) => {
    
    const editor_data = editor.getData();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editor_data;
    const links = tempDiv.querySelectorAll("a");
    links.forEach((link) => {
      if (link.host !== window.location.host) {
        link.setAttribute("target", "_blank");
      }
    });

    const data = tempDiv.innerHTML;
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: data,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      description: undefined,
    }));
  };

  const updatedData = {
    ...formData,
    attachments: formData.attachments?.filter(item => item.file instanceof File) || []
  };

  if (updatedData.attachments.length === 0) {
    delete updatedData.attachments;
  }


  

  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={960}
      >
        <Form
          successPath={"/admin/support-tickets"}
          submitUrl={AdminApi.SUPPORT_TICKET}
          formData={updatedData}
          setFormSuccess={props.setFormSuccess}
          editId={props.editId}
          validate={customValidation}
          customClass={styles.tractionForm}
          multipart={true}
        >
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={12} container sx={{ mt: 3, mb: 3, alignItems: "center" }} className="error-text-color">
              <Grid item xs={2} mr={"0px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Title *
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  name="title"
                  variant="outlined"
                  onChange={(e)=>handleInputChange(e)}
                  value={formData.title}
                  helperText={errors.title}
                  disabled={!!props.editId}
                  error={errors.title !== undefined}

                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"0px"} xs={2}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Assigned to*
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <SelectOptionsApiAuto
                  apiEndpoint={AdminApi.SUPPORT_TICKET_ASSIGNTO}
                  formData={formData}
                  onUpdate={handleUpdate}
                  labelKey="full_name"
                  valueKey="id"
                  name="assign_to_user_id"
                  errorText={errors.assign_to_user_id}

                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }} className="due_date">
              <Grid item mr={"0px"} xs={2}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Due Date*
                </InputLabel>
                </Grid>
              <Grid item xs={8}>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  required
                  id="outlined-required"
                  fullWidth
                  name="due_date"
                  disabled={!!props.editId}
                  minDate={new Date()} // Disables past dates
                  onChange={(date) => {
                    setFormData((formData) => ({
                      ...formData,
                      due_date: date ? formatDateYYMMDD(date) : null,
                    }))

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      due_date: undefined,
                    }));
                    
                  }
                }
                  value={formData.due_date ? formData.due_date : ""}
                  helperText={errors.due_date}
                  
                ></DatePicker>
                 {errors.due_date && (
                <FormHelperText error>{errors.due_date}</FormHelperText>
              )}
              </Grid>
            </Grid>
          
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"0px"} xs={2}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                
                Priority *
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
              <Select
                  disabled={!!props.editId}                 
                 fullWidth
                 value={formData.priority}
                 onChange={(e)=>handleInputChange(e)}
                 name="priority"
               >
                 {priority.map((item,index) => (
                   <MenuItem key={index} value={item.value}>
                     {item.label}
                   </MenuItem>
                 ))}
               </Select>
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"0px"} xs={2}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Status *                </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <Select
                  fullWidth
                  value={formData.status}
                  onChange={(e)=>handleInputChange(e)}
                  name="status"
                >
                  {status.map((item,index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"0px"} xs={2}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Description*
                </InputLabel>
              </Grid>
              <Grid item xs={10}>
                <CKEditorComponent
                  initialValue={formData.description}
                  onChange={handleEditorChange}
                  readOnly={!!props.editId}

                />
                {errors.description && (
                  <FormHelperText error>
                    {errors.description}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mt: 3, mb: 3, alignItems: "center" }} className="error-text-color">
              <Grid item xs={2} mr={"0px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Upload 
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
              <Box>
                <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: "#001c28", // Customize button color
                      color: "#fff",              // Text color
                      "&:hover": {
                        backgroundColor: "#001c289e", // Hover color
                      },
                      padding: "8px 16px",         // Padding
                      fontWeight: "bold",          // Text style
                    }}
                    startIcon={<UploadIcon />} // Add this line to include the upload icon
                  >
                    Upload Attachments
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={handleFileUpload}
                    />
                  </Button>
                  </Box>
               
              </Grid>
            <Grid item xs={12} container sx={{ mt: 3, mb: 3, alignItems: "center" }} className="error-text-color">
              <Grid item xs={2} mr={"0px"}>
              </Grid>
              <Grid item xs={8}>
                  <Box width={"100%"}>
                  {formData.attachments && formData.attachments.length > 0 && (
                  <Grid container item xs={12} marginBottom={3}>
                    {formData.attachments.length > 0
                      ? formData.attachments.map((mediaItem, index) => (
                          <Chip
                            key={index}
                            label={mediaItem.file?.name || mediaItem.file}
                            onDelete={() => handleRemoveItem(index)}
                            style={{
                              marginRight: "5px",
                              marginBottom: "5px",
                            }}
                          />
                        ))
                      : ""}
                    </Grid>
                  )}
                  </Box>
              </Grid>
            </Grid>
            </Grid>
            
       
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
