import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import { api, resolveUrl } from "../../config/apis";

const SelectOptionsApiAuto = ({
  disabled = false,
  apiEndpoint = "",
  formData = {},
  onUpdate = () => {},
  labelKey = "",
  name = "",
  valueKey = "",
  errorText = "",
  paramId = "",
  defaultText = "Select Option",
  customStyle = {},
  optionData,
  queryparams = {},
}) => {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (apiEndpoint) {
          const res = await api.get(resolveUrl(apiEndpoint, paramId || ""), {
            params: {
              ...queryparams,
            },
          });
          setOptions(res.data.data);
          if (optionData) {
            optionData(res.data.data);
          }
          setError(null);
        } else {
          setError("API endpoint not provided.");
        }
      } catch (error) {
        setError("Error fetching options.");
      }
    };

    fetchData();
  }, [apiEndpoint, paramId, formData[name]]);

  const handleChange = (event, value) => {
    onUpdate(name, value ? value[valueKey] : "");
  };

  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option[labelKey] || ""}
        value={options.find((option) => option[valueKey] === formData[name]) || null}
        onChange={handleChange}
        disabled={disabled}
        sx={{
            ...customStyle,
            "& .MuiOutlinedInput-root": {
              height: "40px", 
            },
          }}
          renderInput={(params) => (
          <TextField
            {...params}
            // label={defaultText}
            error={!!errorText}
            helperText={errorText || ""}
            size="small"
            fullWidth
          />
        )}
        isOptionEqualToValue={(option, value) => option[valueKey] === value[valueKey]}
      />
    </>
  );
};

export default SelectOptionsApiAuto;
