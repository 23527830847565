import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CKEditorComponent = ({ initialValue, onReady, onChange, readOnly = false }) => {
  const [editorInstance, setEditorInstance] = useState(null);

  useEffect(() => {
    if (editorInstance) {
      if (readOnly) {
        editorInstance.enableReadOnlyMode("customReadOnlyMode");
      } else {
        editorInstance.disableReadOnlyMode("customReadOnlyMode");
      }
    }
  }, [readOnly, editorInstance]);

  return (
    <CKEditor
      editor={ClassicEditor}
      data={initialValue}
      onReady={(editor) => {
        setEditorInstance(editor);
        if (onReady) {
          onReady(editor);
        }
        if (readOnly) {
          editor.enableReadOnlyMode("customReadOnlyMode");
        }
      }}
      onChange={(event, editor) => {
        if (!readOnly) { // Prevent changes if readOnly is true
          const data = editor.getData();
          if (onChange) {
            onChange(event, editor, data);
          }
        }
      }}
      config={{
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "undo",
          "redo",
        ],
      }}
    />
  );
};

export default CKEditorComponent;
