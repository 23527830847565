import { Box, Grid, Typography, Button, Modal } from "@mui/material";
import styles from "./style.module.css";
import { useParams, Link } from "react-router-dom";
import { api, resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import { useEffect, useState } from "react";
import { DataLoader, IsStudent } from "../../../../helper/helperFunction";
import { useAuth } from "../../../../hooks/auth/auth";
import Moment from "react-moment";
import { HasPermission } from "../../../../helper/helperFunction";
import ApplyJob from "../student-apply/edit";

export const JobsDetailsTab = () => {
  const { state } = useAuth();
  const userIsStudent = IsStudent();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [loading, setLoading] = useState(true);
  const [jobData, setJobData] = useState();

  const params = useParams();
  useEffect(() => {
    const fetchData = async () => {
      if (params.id) {
        try {
          setLoading(true);
          const res = await api.get(
            resolveUrl(AdminApi.JOBS_DETAIL_VIEW, params.id)
          );
          setJobData(res.data.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [params.id]);

  return (
    <>
      {loading ? (
        <DataLoader />
      ) : (
        jobData && (
          <Grid container className={styles.job_cards_wrap} spacing={2}>
            <Grid item xs={12} className={styles.col}>
              <Box className={styles.col_wrap}>
                <Grid
                  container
                  spacing={2}
                  sx={12}
                  justifyContent={"space-between"}
                  className={state.userRole !== "Student" ? styles.small_5 : ""}
                >
                  {state.userRole !== "Student" ? (
                    <Box className={styles.small_box}>
                      <span className={styles.key}>
                        {jobData.applicant_count}
                      </span>
                      <span className={styles.value}>Applicants</span>
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box className={styles.small_box}>
                    <span className={styles.key}>{jobData.job_type}</span>
                    <span className={styles.value}>{jobData.timings}</span>
                  </Box>
                  <Box className={styles.small_box}>
                    <span className={styles.key}>{jobData.salary || "-"}</span>
                    <span className={styles.value}>Salary</span>
                  </Box>
                  <Box className={styles.small_box}>
                    <span className={styles.key}>
                      <Moment format="DD/MM/YYYY">
                        {jobData.last_date_apply}
                      </Moment>
                    </span>
                    <span className={styles.value}>Last Date</span>
                  </Box>
                </Grid>

                <Typography variant="h3">
                  <Link to={"view"}>{jobData.job_title}</Link>
                </Typography>
                <Typography variant="h6">
                  <span>Enterprise</span> : {jobData.location}
                </Typography>
                <Typography variant="h4">Job Type</Typography>
                <span className={styles.chip}>{jobData.job_type}</span>
                <Typography variant="h4">Overview</Typography>
                <Typography variant="p" className={styles.para}>
                  {jobData.company_overview}
                </Typography>

                <Typography variant="h4">Job Description</Typography>
                <div
                  dangerouslySetInnerHTML={{ __html: jobData.job_description }}
                ></div>

                <Typography variant="h4">Skills</Typography>

                {jobData.skills &&
                  jobData.skills.split(",").map((item, index) => (
                    <span className={styles.chip} key={index}>
                      {item.trim()}
                    </span>
                  ))}
                <Box className={styles.btn_wrapper}>
                  <Button
                    onClick={() => window.history.back()}
                    className={styles.cancle + " " + styles.btn}
                  >
                    Back
                  </Button>

                  {userIsStudent ? (
                    <Button
                      className={styles.update + " " + styles.btn}
                      onClick={toggleModal}
                      style={jobData.applied ? { pointerEvents: "none" } : {}}
                    >
                      {jobData.applied ? "Applied" : "Apply"}
                    </Button>
                  ) : (
                    <HasPermission permission="manage-jobs-board.create">
                      <Button className={styles.update + " " + styles.btn}>
                        <Link to={`/admin/jobs/edit/${params.id}`}>Update</Link>
                      </Button>
                    </HasPermission>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )
      )}

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        className={styles.apply_job}
      >
        <Box className="popup" maxWidth={"485px"}>
          <button
            onClick={toggleModal}
            style={{ opacity: 0 }}
            className="close_btn"
          >
            Close
          </button>
          <ApplyJob />
        </Box>
      </Modal>
    </>
  );
};
