import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { api, resolveUrl } from "../../config/apis";
import AdminApi from "../../config/apis/admin";
import { Box, Chip } from "@mui/material";

const CreateableMultiSelect = ({ setSelectedSkills, selectedSkills }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);

  function filterDataByIds(ids) {
    return options && options.filter((item) => ids.includes(item.value));
  }

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const res = await api.get(AdminApi.JOBS_SKILLS_LIST);
        const formattedOptions = res.data.data.map((skill) => ({
          value: skill.id,
          label: skill.skill,
        }));
        setOptions(formattedOptions);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSkills();
  }, []);

  useEffect(() => {
    const filteredData = filterDataByIds(selectedSkills);
    setValues(filteredData);
  }, [selectedSkills]);

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedSkills(selectedValues);
    setValues(selectedOptions);
  };

  const handleCreate = async (inputValue) => {
    setIsLoading(true);
    try {
      const response = await api.post(resolveUrl(AdminApi.SKILL_SAVE), {
        skill: inputValue,
      });

      if (response.data.status === 200) {
        const { id, skill } = response.data.data;
        const newOption = {
          value: id,
          label: skill,
        };
        setOptions([...options, newOption]);
        setValues([...values, newOption]);
        setSelectedSkills([...selectedSkills, id]);
      } else {
        console.error("Skill creation failed:", response.data.error);
      }
    } catch (error) {
      console.error("Error creating option:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setValues((chips) => chips.filter((chip) => chip.value !== chipToDelete));
  };

  return (
    <Box>
      <CreatableSelect
        isMulti
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={options}
        value={values}
      />
      {/* <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, marginTop: 1 }}>
        {values.map((value) => {
          const selectedSkill = options.find(
            (option) => option.value === value.value
          );
          return (
            <Chip
              key={value.value}
              label={selectedSkill ? selectedSkill.label : ""}
              onDelete={handleDelete(value.value)}
              style={{ margin: 1 }}
            />
          );
        })}
      </Box> */}
    </Box>
  );
};

export default CreateableMultiSelect;
