import React, { useState, useEffect } from "react";
import { Box, MenuItem, Modal, Select, TextField } from "@mui/material";
import AdminApi from "../../../../config/apis/admin";
import { Grid, InputLabel } from "@mui/material";
import Form from "../../../../components/forms/Forms";
import { Textarea } from "@mui/joy";
import { api, resolveUrl } from "../../../../config/apis";

const UpdateInfoModal = ({ open, row, closeModal, setReRender }) => {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    address: "",
  });
  const [formSuccess, setFormSuccess] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (formSuccess) {
      closeModal();
      setFormSuccess(false);
      setFormData({
        email: "",
        phone: "",
        address: ""
      });
      setReRender((prevState) => !prevState);
    }
  }, [formSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.USER_MODULE_STUDENT_BASIC_INFO_GET, row?.id)
        );
        const { email, phone, address } = res?.data?.data;
        setFormData({
          email: email,
          phone: phone,
          address: address,
        });
      } catch (error) {
        // Error handling
        console.error(error);
      }
    };

    fetchData();
  }, [row?.id]);

  return (
    <Modal open={open} onClose={closeModal}>
      {row && (
        <Box className="popup" maxWidth={600}>
          <button onClick={closeModal} className="close_btn">
            Close
          </button>
          <Form
            successPath="/admin/user/students"
            submitUrl={AdminApi.USER_MODULE_STUDENT_BASIC_INFO_UPDATE}
            formData={formData}
            editId={row?.id}
            setFormSuccess={setFormSuccess}
          >
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Email</InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <TextField
                  fullWidth
                  name="email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Phone</InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <TextField
                  fullWidth
                  name="phone"
                  variant="outlined"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Address </InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <Textarea
                  name="address"
                  minRows={4}
                  maxRows={4}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={formData.address}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Modal>
  );
};

export default UpdateInfoModal;
