import { Avatar, Box, Typography, Select, MenuItem } from "@mui/material";
import { toTitleCase } from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import { useEffect, useState, useCallback, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateView } from "../../../../components/DateView/DateView";
import FilterBase from "../filter/filter-base";
import { api, resolveUrl } from "../../../../config/apis";
import Flag from "../../../../static/images/Flag.png";
import FlagFalse from "../../../../static/images/Flag-false.png";
import styles from "./manage-user-student.module.css";
import CSVDownloadButton from "../../../../components/downloadCSV";
import ImageView from "../../../../components/imageShow";
import { HasPermission } from "../../../../helper/helperFunction";
import EnrollHistroyModal from "./enroll-histroy";
import { useFilterContext } from "../../../../context/filter-context";
import EditFlag from "./editflag";
import UpdateInfoModal from "./update-basic-info";

export default function ViewStudentUsers() {
  const navigate = useNavigate();

  const [modalState, setModalState] = useState({
    enrollHistory: false,
    updateInfo: false,
    flag: false,
  });

  const [rowData, setRowData] = useState({});
  

  const toggleModal = (modalName, row = {}) => {
    // Only clear rowData if the modal is being closed
    setModalState((prev) => {
      const isClosing = prev[modalName];
      
      if (isClosing) {
        setRowData({});
      } else {
        setRowData(row); // Set row data only when opening
      }
  
      return {
        ...prev,
        [modalName]: !prev[modalName],
      };
    });
  };
  

 
  const { filtersData, updateFilters } = useFilterContext();

  const handleFiltersChange = (filterState) => {
    updateFilters({
      student: filterState,
    });
  };

  const handleOpenEnrollHistory = (row) => {
    toggleModal("enrollHistory", row);
  };
  
  const handleOpenUpdateInfo = (row) => {
    toggleModal("updateInfo", row);
  };
  
  const handleFlagOpen = (row) => {
    toggleModal("flag", row);
  };
  

  const [reRender, setReRender] = useState(false);

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.name} />
          {toTitleCase(`${row.name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "200px",
      selector: "name",
    },
    {
      selector: (row) => row.email,
      name: "Email",
      sortable: true,
      minWidth: "300px",
    },
    {
      selector: (row) => row.phone,
      name: "Phone",
      sortable: true,
      minWidth: "160px",
    },
    {
      selector: (row) => row.cnic,
      name: "CNIC",
      sortable: true,
      minWidth: "160px",
    },
    {
      cell: (row) => (
        <span style={{ cursor: "pointer" }} onClick={() => handleFlagOpen(row)}>
          {row.is_flagged ? (
            <img src={Flag} alt="Flag" />
          ) : (
            <img src={FlagFalse} alt="Flag" />
          )}
        </span>
      ),
      selector: "is_flagged",
      name: "Flag Status",
      width: "150px",
      sortable: true,
    },

    {
      selector: (row) => row.city_name,
      name: "City",
      sortable: true,
    },

    {
      cell: (row) => <DateView date={row.enrolled_on} />,
      name: "Registration date",
      selector: "enrolled_on",
      sortable: true,
      minWidth: "200px",
    },
    {
      cell: (row) => row.organization,
      name: "Organization",
      minWidth: "150px",
      sortable: true,
      selector: "organization",
    },

    {
      cell: (row) => (
        <Typography
          variant="body1"
          sx={{ textTransform: "capitalize" }}
          className={row.fee_status ? styles.due : styles.paid}
        >
          {row.fee_status}
        </Typography>
      ),
      minWidth: "150px",
      name: "Fee Status",
      sortable: true,
      selector: "fee_status",
    },
    {
      selector: (row) => row.current_course,
      minWidth: "200px",
      name: "Current Courses",
      sortable: true,
    },
    // {
    //   cell: (row) => (
    //     <Typography variant="body1" fontWeight={"700"}>
    //       {row.avarge_score}
    //     </Typography>
    //   ),
    //   minWidth: "150px",
    //   name: "Avg score",
    //   sortable: true,
    //   selector: "avarge_score",
    // },
    {
      selector: "student_status",
      cell: (row) => (
        <Typography
          variant="body1"
          sx={{ textTransform: "capitalize" }}
          className={
            row.student_status === "Active" ? styles.active : styles.archived
          }
        >
          {row.student_status === "Active" ? "Active" : "Inactive"}
        </Typography>
      ),
      minWidth: "150px",
      name: "Status",
      sortable: true,
    },
    {
      cell: (row) => (
        <span className={styles.view_history} onClick={() => handleOpenEnrollHistory(row)}>
          View
        </span>
      ),
      width: "160px",
      name: "Course History",
      sortable: false,
    },
    {
      cell: (row) => (
        <span className={styles.view_history} onClick={() => handleOpenUpdateInfo(row)}>
          Update Info
        </span>
      ),
      width: "160px",
      name: "Update Basic Info",
      sortable: false,
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/user/student/view/${id}`);
  };

  const onEdit = async (id) => {
    navigate(`/admin/user/student/edit/${id}`);
  };

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.USER_MODULE_STUDENTS_DEL, id));
  };

  const [currentValue, setCurrentValue] = useState("desc");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  const [reportData, setReportData] = useState([]);
  const [reportDataCsv, setReportDataCsv] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(
        AdminApi.USER_MODULE_STUDENT_DOWNLOAD_CSV,
        {
          params: {
            sort_order: currentValue,
            download_csv:true
          },
        }
      );
      const responsecsv = await api.get(
        AdminApi.USER_MODULE_STUDENTS_ENROLL_HISTORY_CSV
      );

      const modifiedData = response.data.data.map((item) => {
        const { status, ...rest } = item;
        return rest;
      });

      setReportData(modifiedData);
      setReportDataCsv(responsecsv.data.data ?? []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [currentValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="container">
        <div className="course-content">
          <h1 className="main-heading">Student</h1>

          <HasPermission permission="manage-students.create">
            <Box className="btn-wrapper">
              <Link to={"/admin/user/student/edit"}>Add Student</Link>
            </Box>
          </HasPermission>
        </div>
        <div className={`${styles.filter_flex} ${styles.filter_search}`}>
          <FilterBase
            onFiltersChange={handleFiltersChange}
            currentFilterData={filtersData.student}
          />
          <div className={styles.filter_flex}>
            <Box className={styles.filter_btn}>
              <CSVDownloadButton
                fileName={"Students"}
                data={reportData ?? []}
              />
              <CSVDownloadButton
                fileName={"Students"}
                data={reportDataCsv ?? []}
                downloadText="Download History"
              />
            </Box>
            <Box>
              <Select
                value={currentValue}
                onChange={handleChange}
                className="select-menu"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
          </div>
        </div>

        <div className="page-container table-phases">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.USER_MODULE_STUDENTS_PAGINATE}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            filters={filtersData.student}
            sortOrder={currentValue}
            actions={{
              view: true,
            }}
            module="manage-students"
            refreshHandle={reRender}
          />
        </div>
      </div>
      <EnrollHistroyModal
        open={modalState.enrollHistory}
        closeModal={() => toggleModal("enrollHistory")}
        row={rowData}
      />
      <UpdateInfoModal
        open={modalState.updateInfo}
        closeModal={() => toggleModal("updateInfo")}
        row={rowData}
        setReRender={setReRender}
      />
      <EditFlag
        open={modalState.flag}
        closeModal={() => toggleModal("flag")}
        row={rowData}
        setReRender={setReRender}
      />
    </>
  );
}
