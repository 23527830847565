import { Box } from "@mui/material";
import styles from "../style.module.css";
import ActionButtons from "../../../../components/dataTables/ActionButtons";
import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import Moment from "react-moment";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { api, resolveUrl } from "../../../../config/apis";

export const StudentJobRecordsTable = (params) => {
  const nevigate = useNavigate();

  const tableColumns = [
    {
      selector: (row) => row.student_id,
      name: "ID",
      width: "100px",
      sortable: true,
    },
    {
      cell: (row) => (
        <Tooltip title={row.student_name} arrow>
          <div>{row.student_name}</div>
        </Tooltip>
      ),
      name: "Name",
      sortable: true,
      selector: "student_name",
      width: "200px",

    },
    {
      cell: (row) => (
        <Tooltip title={row.student_email} arrow>
          <div>{row.student_email}</div>
        </Tooltip>
      ),
      name: "Email",
      width: "250px",
      sortable: true,
      selector: "student_email",
    },
    {
      cell: (row) => (
        <Tooltip title={row.company_name} arrow>
          <div>{row.company_name}</div>
        </Tooltip>
      ),
      name: "Company",
      width: "200px",
      sortable: true,
      selector: "company_name",
    },
    {
      cell: (row) => (
        <Tooltip title={row.job_title} arrow>
          <div>{row.job_title}</div>
        </Tooltip>
      ),
      name: "Position",
      sortable: true,
      selector: "job_title",
      width: "200px",
      wrap: "false",
    },
    {
      cell: (row) => (
        <Tooltip title={row.job_type} arrow>
          <div>{row.job_type}</div>
        </Tooltip>
      ),
      name: "Job status",
      sortable: true,
      selector: "job_type",
      width: "150px",

    },
    {
      cell: (row) => (
        <Moment format="DD/MM/YYYY">{row.joining_date}</Moment>
      ),
      name: "Date of Joining",
      width: "150px",
      sortable: true,
      selector: "joining_date",
    },
    {
      cell: (row) => (
        <Tooltip title={row.salary} arrow>
          <div>{row.salary}</div>
        </Tooltip>
      ),
      name: "Salary",
      sortable: true,
      selector: "salary",
      width: "200px"
    },
    {
      cell: (row) => (
        <Tooltip title={row.work_experience} arrow>
          <div>{row.work_experience}</div>
        </Tooltip>
      ),
      name: "Total Work Experience",
      width: "200px",
       sortable: true,
      selector: "work_experience"
    },
    {
      cell: (row) => (
        <Tooltip title={row.referrer} arrow>
          <div>{row.referrer}</div>
        </Tooltip>
      ),
      name: "Referred by",
      sortable: true,
      selector: "referrer",
      width: "200px"

    },
    {
      cell: (row) =>
        row.end_date ? (
          <Moment format="DD/MM/YYYY">{row.end_date}</Moment>
        ) : (
          "-"
        ),
      name: "Date of Leaving",
      width: "200px",
      sortable: true,
      selector: "end_date"
    },
    {
      cell: (row) =>
        row.leaving_reason ? (
          <Tooltip title={row.leaving_reason} arrow>
            <div className="elipsis">{row.leaving_reason}</div>
          </Tooltip>
        ) : (
          "-"
        ),
      name: "Reason for Leaving",
      width: "200px",
      sortable: true,
      selector: "leaving_reason"
    },
  ];

  const onEdit = (id) => {
    nevigate(`/admin/student-job-records/edit/${id}`);
  };
  const onDelete = async (id) => {
    return await api.delete(
      resolveUrl(AdminApi.STUDENT_JOB_RECORDS_LIST_DELETE, id)
    );
  };
  return (
    <>
      <Box
        className={`page-container table-phases global_font_style ${styles.table_wrap}`}
        marginTop={5}
      >
        {params.children}
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.STUDENT_JOB_RECORDS_LIST}
          sortOrder={params.currentValue}
          filters={params.filters}
          module="manage-job-record"
          pagination={params.pagination}
          onEdit={onEdit}
          onDelete={onDelete}
          actions={{
            view: false,
          }}
        />
      </Box>
    </>
  );
};
