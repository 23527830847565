import { useState, useEffect } from "react";
import { api, resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import {
  InputLabel,
  TextField,
  Typography,
  FormHelperText,
  MenuItem,
  Chip,
} from "@mui/material";
import { Box, Grid } from "@mui/material";
import Form from "../../../../components/forms/Forms";
import { Textarea } from "@mui/joy";
import SelectOptions from "../../../../components/Select";
import ImageUpload from "../../../../components/UploadImage";
import SelectOptionsApi from "../../../../components/SelectApi";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import validationSchema from "./validation";
import styles from "./manage-user-student.module.css";
import useForm from "../../../../hooks/useForm/useForm";
import { formatDateYYMMDD } from "../../../../helper/helperFunction";
import moment from "moment";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

export default function EditStudent() {
  const initialState = {
    first_name: "",
    last_name: "",
    father_name: "",
    cnic: null,
    emergency_number: "",
    registration_number: null,
    email: "",
    phone: "",
    gender: "",
    street_address: "",
    country_id: "",
    state_id: "",
    city_id: "",
    zip_code: "",
    is_studying: "",
    registration_date: "",
    is_flagged: "0",
    status: null,
    flag_reason: "",
    profile_image: "",
    qualification_id: "",
    qualification_field_id: "",
    section_id: "",
    date_of_birth: "",
    cohort_id: "",
    organization_id: "",
    fund_type: "",
    registration_fee: "",
    joining_date: "",
    original_location_id: null,
    currency_id: null,
    course_id: null,
    section_id: null,
    document: null,
  };

  const {
    formData,
    setFormData,
    errors,
    handleImageChange,
    handleInputChange,
    handleUpdate,
    customValidation,
    setErrors,
  } = useForm(initialState, validationSchema);

  const [uploadPreviewProp, setUploadPreviewProp] = useState(null);

  const genderOptions = [
    { id: "Male", options: "Male" },
    { id: "Female", options: "Female" },
    { id: "Other", options: "Other" },
  ];
  const is_flagged = [
    { id: "1", option: "Yes" },
    { id: "0", option: "No" },
  ];
  const is_studying = [
    { id: "1", option: "Yes" },
    { id: "0", option: "No" },
  ];
  const status = [
    { id: "1", option: "Active" },
    { id: "0", option: "InActive" },
  ];
  const fund_type = [
    { id: "partially_funded", option: "Partially Funded" },
    { id: "fully_funded", option: "Fully Funded" },
    { id: "self_paid", option: "Self Paid" },
  ];
  const registration_fee = [
    { id: "500", option: "500" },
    { id: "1000", option: "1000" },
    { id: "1500", option: "1500" },
    { id: "2000", option: "2000" },
    { id: "2500", option: "2500" },
    { id: "3000", option: "3000" },
  ];

  const params = useParams();

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.USER_MODULE_STUDENTS_VIEW, params.id)
          );
          if (res.status === 200) {
            const { data } = res.data;
            setFormData({
              registration_number: data.registration_number || "",
              first_name: data.first_name || "",
              last_name: data.last_name || "",
              emergency_number: data.emergency_number || "",
              father_name: data.father_name || "",
              cnic: data.cnic || "",
              email: data.email || "",
              phone: data.phone || "",
              gender: data.gender || "",
              street_address: data.street_address || "",
              country_id: data.country_id || "",
              state_id: data.state_id || "",
              city_id: data.city_id || "",
              zip_code: data.zip_code || "",
              is_studying: data.is_studying || "0",
              status: data.status || "0",
              is_flagged: data.is_flagged.toString(),
              flag_reason: data.flag_reason || "",
              qualification_id: data.qualification_id || "",
              qualification_field_id: data.qualification_field_id || "",
              section_id: data.section_id || "",
              cohort_id: data.cohort_id || "",
              course_id: data.course_id || "",
              organization_id: data.organization_id || "",
              fund_type: data.payment || "",
              registration_fee: data.registration_fee || "",
              currency_id: data.currency_id || "",
              original_location_id: data.original_location_id || "",
              registration_date: data.registration_date
                ? moment(data.registration_date).format("YYYY-MM-DD")
                : "",
              joining_date: data.joining_date
                ? moment(data.joining_date).format("YYYY-MM-DD")
                : "",
              date_of_birth: data.date_of_birth
                ? moment(data.date_of_birth).format("YYYY-MM-DD")
                : "",
            });
            setUploadPreviewProp(data.profile_image || "");

            const fileName =
              data.document &&
              data.document.substring(data.document.lastIndexOf("/") + 1);

            setSelectedFile({
              url: data.document || "",
              name: fileName,
            });
          } else {
            // console.error("Error: Unexpected status code", res.status);
          }
        }
      } catch (error) {
        // console.error("Error:", error);
      }
    };

    const fetchLocations = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.LOCATION_PAGINATE));

        setLocations(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    fetchData();
    fetchLocations();
  }, [params.id]);

  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  const handleFeeUpdate = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      registration_fee: value ? Number(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  useEffect(() => {
    if (formData.fund_type === "self_paid") {
      setFormData({ ...formData, organization_id: "" });
    }
  }, [formData.fund_type]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    setFormData({
      ...formData,
      document: file,
    });
    const blob = new Blob([file], { type: file.type });
    const blobUrl = URL.createObjectURL(blob);
    setSelectedFile({
      url: blobUrl,
      name: fileName,
    });
  };
  const handleDeleteFile = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
    setFormData({
      ...formData,
      document: null,
    });
  };

  const queryParameters = {
    course_id: formData.course_id,
    cohort_id: formData.cohort_id,
  };
  return (
    <>
      <div className="container global-user">
        <Typography variant="h1" className="main-heading">
          {params.id ? "Edit Student" : "Add Student"}
        </Typography>
        <Grid container className={`page-container ${styles.addStudent}`}>
          <Grid item xs={12} className="inner-container">
            <Form
              successPath="/admin/user/students"
              submitUrl={AdminApi.USER_MODULE_STUDENTS_SAVE}
              formData={formData}
              editId={params.id}
              validate={customValidation}
              multipart={true}
            >
              <h2 className="content-title">Personal Info</h2>
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={9} order={{ lg: 1, xs: 2 }}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Registration No
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="registration_number"
                        variant="outlined"
                        value={formData.registration_number}
                        onChange={handleInputChange}
                        error={errors.registration_number !== undefined}
                        helperText={errors.registration_number}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        First Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="first_name"
                        variant="outlined"
                        placeholder="Enter First Name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        error={errors.first_name !== undefined}
                        helperText={errors.first_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Last Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="last_name"
                        variant="outlined"
                        placeholder="Enter Last Name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        error={errors.last_name !== undefined}
                        helperText={errors.last_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Father/Husband Name
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="father_name"
                        variant="outlined"
                        placeholder="Enter Father / Husband Name"
                        value={formData.father_name}
                        onChange={handleInputChange}
                        error={errors.father_name !== undefined}
                        helperText={errors.father_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>CNIC *</InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="cnic"
                        variant="outlined"
                        placeholder="Enter CNIC"
                        value={formData.cnic}
                        onChange={handleInputChange}
                        error={errors.cnic !== undefined}
                        helperText={errors.cnic}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Emergency Number
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="emergency_number"
                        variant="outlined"
                        placeholder="Enter Emergency Number"
                        value={formData.emergency_number}
                        onChange={handleInputChange}
                        error={errors.emergency_number !== undefined}
                        helperText={errors.emergency_number}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center " }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>Email*</InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.email !== undefined}
                        helperText={errors.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Whatsapp
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="phone"
                        variant="outlined"
                        placeholder="Enter Phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        error={errors.phone !== undefined}
                        helperText={errors.phone}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "baseline", pr: 3 }}
                  >
                    <Grid item xl={2} sm={3.1}>
                      <InputLabel> Date of Birth </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <DatePicker
                        calendarAriaLabel="Toggle calendar"
                        clearAriaLabel="Clear value"
                        dayAriaLabel="Day"
                        monthAriaLabel="Month"
                        nativeInputAriaLabel="Date"
                        selected={
                          formData.date_of_birth
                            ? new Date(formData.date_of_birth)
                            : null
                        }
                        value={
                          formData.date_of_birth ? formData.date_of_birth : ""
                        }
                        onChange={(date) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            date_of_birth: date ? formatDateYYMMDD(date) : null,
                          }));

                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            date_of_birth: undefined,
                          }));
                        }}
                        yearAriaLabel="Year"
                      />

                      {errors.date_of_birth && (
                        <FormHelperText error>
                          {errors.date_of_birth}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xl={1} sm={2} ml={2}>
                      <InputLabel> Gender </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptions
                        options={genderOptions}
                        formData={formData}
                        onUpdate={handleUpdate}
                        name="gender"
                        labelKey="options"
                        valueKey="id"
                        errorText={errors.gender}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Street Address
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="street_address"
                        variant="outlined"
                        placeholder="Street Address"
                        value={formData.street_address}
                        onChange={handleInputChange}
                        error={errors.street_address !== undefined}
                        helperText={errors.street_address}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center", pr: 3 }}
                  >
                    <Grid item xl={2} sm={3.1}>
                      <InputLabel> Country* </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.COUNTRIES}
                        formData={formData}
                        onUpdate={handleUpdate}
                        labelKey="name"
                        name="country_id"
                        valueKey="id"
                        defaultText="Select Option"
                        errorText={errors.country_id}
                      />
                    </Grid>
                    <Grid item xl={1} sm={2} ml={2}>
                      <InputLabel> State* </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.STATES}
                        paramId={formData.country_id ?? ""}
                        formData={formData}
                        onUpdate={handleUpdate}
                        labelKey="name"
                        name="state_id"
                        valueKey="id"
                        errorText={errors.state_id}
                        disabled={!formData.country_id}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ alignItems: "center", pr: 3 }}
                  >
                    <Grid item xl={2} sm={3.1}>
                      <InputLabel> City* </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.CITIES}
                        paramId={formData.state_id ?? ""}
                        formData={formData}
                        onUpdate={handleUpdate}
                        labelKey="name"
                        name="city_id"
                        valueKey="id"
                        errorText={errors.city_id}
                        disabled={!formData.state_id}
                      />
                    </Grid>
                    <Grid item xl={1} sm={2} ml={2}>
                      <InputLabel> Zip </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <TextField
                        fullWidth
                        name="zip_code"
                        variant="outlined"
                        placeholder="zip code"
                        value={formData.zip_code}
                        onChange={handleInputChange}
                        error={errors.zip_code !== undefined}
                        helperText={errors.zip_code}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 6 }}>
                    <h2>Other Info</h2>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xl={2} sm={3}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Currently studying?
                        </InputLabel>
                      </Grid>
                      <Grid item xl={6} sm={8}>
                        <SelectOptions
                          options={is_studying}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="option"
                          valueKey="id"
                          name="is_studying"
                          errorText={errors.is_studying}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center", pr: 3 }}
                    >
                      <Grid item xl={2} sm={3.1}>
                        <InputLabel> Education level </InputLabel>
                      </Grid>
                      <Grid item xl={2.5} sm={3}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.EDUCATION_LEVEL}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="qualification_id"
                          valueKey="id"
                          errorText={errors.qualification_id}
                        />
                      </Grid>
                      <Grid item xl={1} sm={2} ml={2}>
                        <InputLabel> Group </InputLabel>
                      </Grid>
                      <Grid item xl={2.5} sm={3}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.EDUCATION_GROUP}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="qualification_field_id"
                          valueKey="id"
                          errorText={errors.qualification_field_id}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container sx={{ alignItems: "center" }}>
                      <Grid item xl={2} sm={3}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Upload Document
                        </InputLabel>
                      </Grid>
                      <Grid item xl={6} sm={8} container alignItems={"center"}>
                        <div className="upload_doc">
                          {formData.document && (
                            <span className="file_path">
                              {formData.document.name}
                            </span>
                          )}
                          <button className="upload_btn">
                            <input
                              type="file"
                              id="fileInput"
                              accept=".pdf"
                              onChange={handleFileChange}
                            />
                            Browse
                          </button>
                        </div>
                        {selectedFile && (
                          <div className="upload_file_preview">
                            <InsertDriveFileIcon />
                            <a
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                              }}
                              href={`${SERVER_KEY}${selectedFile.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {selectedFile && (
                                <>
                                  <span>{selectedFile.name}</span>
                                </>
                              )}
                            </a>
                            <span onClick={handleDeleteFile}>
                              <HighlightOffRoundedIcon />
                            </span>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: 6 }}>
                    <h2>Admission info</h2>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "baseline", pr: 3 }}
                    >
                      <Grid item xl={2} sm={3.1}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Registration Date*
                        </InputLabel>
                      </Grid>
                      <Grid item xl={2.3} sm={3}>
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          required
                          id="outlined-required"
                          fullWidth
                          name="registration_date"
                          selected={
                            formData.registration_date
                              ? new Date(formData.registration_date)
                              : null
                          }
                          value={
                            formData.registration_date
                              ? formData.registration_date
                              : ""
                          }
                          onChange={(date) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              registration_date: date
                                ? formatDateYYMMDD(date)
                                : null,
                            }));

                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              registration_date: undefined,
                            }));
                          }}
                          showTimeSelect={false} // Set this to false to hide the time picker
                          size="small"
                          placeholderText="Select a date" // Add the placeholder text here
                        />

                        {errors.registration_date && (
                          <FormHelperText error>
                            {errors.registration_date}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xl={1.3} sm={2} ml={2}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Joining Date*
                        </InputLabel>
                      </Grid>
                      <Grid item xl={2.3} sm={3}>
                        <DatePicker
                          selected={
                            formData.joining_date
                              ? new Date(formData.joining_date)
                              : null
                          }
                          dateFormat="yyyy-MM-dd"
                          required
                          id="outlined-required"
                          fullWidth
                          name="joining_date"
                          value={
                            formData.joining_date ? formData.joining_date : ""
                          }
                          onChange={(date) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              joining_date: date
                                ? formatDateYYMMDD(date)
                                : null,
                            }));

                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              joining_date: undefined,
                            }));
                          }}
                          showTimeSelect={false}
                          size="small"
                          placeholderText="Select a date"
                        />
                        {errors.joining_date && (
                          <FormHelperText error>
                            {errors.joining_date}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xl={2} sm={3}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Location*
                        </InputLabel>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        sm={8}
                        className={styles.locationFields}
                      >
                        <TextField
                          select
                          fullWidth
                          placeholder="Enter location"
                          value={formData.original_location_id}
                          name="original_location_id"
                          variant="outlined"
                          onChange={handleOnChange}
                          error={errors.original_location_id !== undefined}
                          helperText={errors.original_location_id}
                        >
                          {locations &&
                            locations.map((location, index) => (
                              <MenuItem key={index.id} value={location.id}>
                                {location.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ alignItems: "center", pr: 3, mb: 3 }}
                    >
                      <Grid item xl={2} sm={3.1}>
                        <InputLabel> Cohort* </InputLabel>
                      </Grid>
                      <Grid item xl={6.1} sm={8.3}>
                        <SelectOptionsApi
                          apiEndpoint={
                            formData.original_location_id
                              ? AdminApi.COHORTS_LIST_BY_LOCATION
                              : ""
                          }
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="cohort_id"
                          valueKey="id"
                          errorText={errors.cohort_id}
                          paramId={formData.original_location_id ?? ""}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ alignItems: "center", pr: 3, mb: 3 }}
                    >
                      <Grid item xl={2} xs={3.1}>
                        <InputLabel>Course*</InputLabel>
                      </Grid>
                      <Grid item xl={2.3} xs={3}>
                        <SelectOptionsApi
                          apiEndpoint={
                            formData.cohort_id
                              ? AdminApi.COURSES_LIST_BY_LOCATION
                              : ""
                          }
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="course_id"
                          valueKey="id"
                          paramId={formData.cohort_id ?? ""}
                          errorText={errors.course_id}
                        />
                      </Grid>
                      <Grid item xl={1} marginLeft={2} xs={2}>
                        <InputLabel> Section* </InputLabel>
                      </Grid>
                      <Grid item xl={2.5} xs={3}>
                        <SelectOptionsApi
                          apiEndpoint={
                            formData.course_id
                              ? AdminApi.COURSE_COHORT_SECTIONS
                              : ""
                          }
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="section_id"
                          valueKey="id"
                          errorText={errors.section_id}
                          paramId={formData.course_id ?? ""}
                          queryparams={queryParameters}
                        />
                      </Grid>
                    </Grid>

                    {formData && formData.fund_type !== "self_paid" ? (
                      <Grid
                        item
                        xs={12}
                        container
                        sx={{ mb: 3, alignItems: "center", pr: 2 }}
                      >
                        <Grid item xl={2} sm={3.1}>
                          <InputLabel> Organization* </InputLabel>
                        </Grid>
                        <Grid item xl={6} sm={8.1}>
                          <SelectOptionsApi
                            apiEndpoint={AdminApi.ORGANIZATIONS}
                            formData={formData}
                            onUpdate={handleUpdate}
                            labelKey="name"
                            valueKey="id"
                            name="organization_id"
                            errorText={errors.organization_id}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <Grid
                      xs={12}
                      container
                      sx={{ alignItems: "center", mb: 3, pr: 3 }}
                    >
                      <Grid item xl={2} sm={3.1}>
                        <InputLabel> Payment Info* </InputLabel>
                      </Grid>
                      <Grid item xl={2.3} sm={3}>
                        <SelectOptions
                          options={fund_type}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="option"
                          name="fund_type"
                          valueKey="id"
                          errorText={errors.fund_type}
                        />
                      </Grid>

                      <Grid item xl={1.3} sm={2} ml={2}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Registration Fee*
                        </InputLabel>
                      </Grid>
                      <Grid item xl={2.3} sm={3}>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            maxWidth: "200px",
                          }}
                          className="currncy_ammount"
                        >
                          <Box
                            sx={{
                              maxWidth: "120px",
                            }}
                            className="SelectOptionsApi"
                          >
                            <SelectOptionsApi
                              apiEndpoint={AdminApi.CURRENCY_VIEW}
                              formData={formData}
                              onUpdate={handleUpdate}
                              name={"currency_id"}
                              labelKey="symbol"
                              valueKey="id"
                              defaultText="Currency"
                              customStyle={{ border: "0", paddingRight: "0" }}
                            />
                          </Box>
                          <TextField
                            className="registration_fee"
                            fullWidth
                            name="registration_fee"
                            variant="outlined"
                            placeholder="fee"
                            value={formData.registration_fee}
                            InputProps={{
                              sx: {
                                height: "40px",
                                padding: "0 10px",
                              },
                            }}
                            inputProps={{
                              type: "number",
                              pattern: "[0-9]",
                            }}
                            onChange={handleFeeUpdate}
                            error={errors.registration_fee !== undefined}
                          />
                        </Box>
                        {errors.currency_id && (
                          <FormHelperText error>
                            {errors.currency_id}
                          </FormHelperText>
                        )}
                        {errors.registration_fee && (
                          <FormHelperText error>
                            {errors.registration_fee}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center", pr: 2 }}
                    >
                      <Grid item xl={2} sm={3.1}>
                        <InputLabel> Status* </InputLabel>
                      </Grid>
                      <Grid item xl={6} sm={8.1}>
                        <SelectOptions
                          options={status}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="option"
                          valueKey="id"
                          name="status"
                          errorText={errors.status}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center", pr: 2 }}
                    >
                      <Grid item xl={2} sm={3.1}>
                        <InputLabel> Flagged*</InputLabel>
                      </Grid>
                      <Grid item xl={6} sm={8.1}>
                        <SelectOptions
                          options={is_flagged}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="option"
                          valueKey="id"
                          name="is_flagged"
                          errorText={errors.is_flagged}
                        />
                      </Grid>
                    </Grid>
                    {formData.is_flagged === "1" ? (
                      <Grid
                        item
                        xs={12}
                        container
                        sx={{ mb: 3, alignItems: "center", pr: 2 }}
                      >
                        <Grid item xl={2} sm={3.1}>
                          <InputLabel> Comment for flag* </InputLabel>
                        </Grid>
                        <Grid item xl={6} sm={8.1}>
                          <Textarea
                            name="flag_reason"
                            minRows={4}
                            maxRows={4}
                            onChange={handleInputChange}
                            variant="outlined"
                            value={formData.flag_reason}
                            error={errors.flag_reason !== undefined}
                            helperText={errors.flag_reason}
                          />
                          {errors.flag_reason && (
                            <FormHelperText error>
                              {errors.flag_reason}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>

                <Grid
                  item
                  lg={3}
                  marginBottom={5}
                  order={{ lg: 2, xs: 1 }}
                  className="circle upload-image"
                  container
                  justifyContent={{ lg: "flex-end", xs: "flex-start" }}
                >
                  <ImageUpload
                    name="profile_image"
                    label="Upload Image"
                    previewWidth={200}
                    maxFileSize={5242880}
                    onChange={handleImageChange}
                    uploadPreviewProp={uploadPreviewProp}
                    isRequired={false}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
