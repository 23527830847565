import {
  Box,
  Grid,
  InputLabel,
  Chip,
  Button,
} from "@mui/material";
import { useEffect } from "react";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";
import styles from "./manage-support-ticket.module.css";
import "react-datepicker/dist/react-datepicker.css";
import UploadIcon from '@mui/icons-material/Upload';


export const TicketAttachment = (props) => {
 

  const defaultFormData = {
 
    attachments: [],
    delete_id:[]
  };

  const [formData, setFormData] = useState(defaultFormData);
  const handleCloseModal = () => {
    props.setModalOpen(false);
    setErrors({});
    setFormData(defaultFormData);
  };

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    // title: yup.string().required("This field required"),
    // assign_to_user_id: yup.string().required("This field required"),
    // priority: yup.string().required("This field required"),
    // due_date: yup.string().required("This field required"),
    // status: yup.string().required("This field required"),
  });

  

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };



 
  const handleFileUpload = (event) => {
    const files = event.target.files;

    const updatedMediaData = {
      ...formData,
      attachments: [
        ...formData.attachments,
        ...Array.from(files).map((file) => ({
          file,
        })),
      ],
    };
    setFormData(updatedMediaData);
  };
  const [mediaId, setMediaId] = useState([]);

  const handleRemoveItem = (index) => {
    const updatedmediaId = mediaId;
    const removeId = updatedmediaId[index];
    updatedmediaId.splice(index, 1);

    const updatedUploadFiles = [...formData.attachments];
    updatedUploadFiles.splice(index, 1);

    const updatedMediaData = {
      ...formData,
      attachments: updatedUploadFiles,
      delete_id: [...formData.delete_id,removeId ?? null], 
    };

    setFormData(updatedMediaData);
  };





  const updatedData = {
    ...formData,
    attachments: formData.attachments?.filter(item => item.file instanceof File) || []
  };

  if (updatedData.attachments.length === 0) {
    delete updatedData.attachments;
  }


    
  useEffect(() => {
    
    if (props.ticketId) {
      const fetchEditData = async () => {
        try {
          const response = await api.get(
            resolveUrl(AdminApi.SUPPORT_TICKET_ATTACH_GET, props.ticketId)
          );
          const { attachments} = response.data;
          const mediaId = [];

          if (attachments) {
            attachments.forEach((item) => {
              mediaId.push(item.id);
            });
          }
          setMediaId(mediaId);
          setFormData({
            attachments: attachments ?? [],
            delete_id:[]

          });
         
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchEditData();
    }
  }, [props.ticketId,props.modalOpen]);
  

  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={550}
      >
        <Form
          successPath={"/admin/support-tickets"}
          submitUrl={AdminApi.SUPPORT_TICKET_ATTACH}
          formData={updatedData}
          setFormSuccess={props.setFormSuccess}
          editId={props.ticketId}
          validate={customValidation}
          customClass={styles.tractionForm}
          multipart={true}
        >
          <span className={styles.comment_tagline}>Attach a file to Issue</span>
          <h2 className={styles.comment_title}>{props.ticketTitle}</h2>
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={12} container sx={{ mt: 3, mb: 3, alignItems: "center" }} className="error-text-color">
              <Grid item xs={2} mr={"0px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Upload *
                </InputLabel>
              </Grid>
              <Grid item xs={8}>
              <Box>
                <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: "#001c28", // Customize button color
                      color: "#fff",              // Text color
                      "&:hover": {
                        backgroundColor: "#001c289e", // Hover color
                      },
                      padding: "8px 16px",         // Padding
                      fontWeight: "bold",          // Text style
                    }}
                    startIcon={<UploadIcon />} // Add this line to include the upload icon
                  >
                    Upload Attachments
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={handleFileUpload}
                    />
                  </Button>
                  </Box>
               
              </Grid>
            <Grid item xs={12} container sx={{ mt: 3, mb: 3, alignItems: "center" }} className="error-text-color">
              <Grid item xs={2} mr={"0px"}>
              </Grid>
              <Grid item xs={8}>
                  <Box width={"100%"}>
                  {formData.attachments && formData.attachments.length > 0 && (
                  <Grid container item xs={12} marginBottom={3}>
                    {formData.attachments.length > 0
                      ? formData.attachments.map((mediaItem, index) => (
                          <Chip
                            key={index}
                            label={mediaItem.file?.name || mediaItem.files}
                            onDelete={() => handleRemoveItem(index)}
                            style={{
                              marginRight: "5px",
                              marginBottom: "5px",
                            }}
                          />
                        ))
                      : ""}
                    </Grid>
                  )}
                  </Box>
              </Grid>
            </Grid>
            </Grid>
            
       
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
