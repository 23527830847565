import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import ImageView from "../../../../components/imageShow";
import { toTitleCase } from "../../../../helper/helperFunction";
import styles from "./manage-user-student.module.css";

const EnrollHistroyModal = ({ open, row, closeModal }) => {

  const tableColumns = [
    {
      selector: (row) => row.course_name,
      name: "Program",
      sortable: true,
    },
    {
      selector: (row) => row.cohort_name,
      name: "Cohort",
      sortable: true,
    },
    {
      selector: (row) => row.section_name,
      name: "Section",
      sortable: true,
    },
    {
      selector: (row) => row.section_status + " section",
      name: "Section status",
      sortable: true,
    },
  ];

  return (
    <Modal open={open} onClose={closeModal}>
      <Box className="popup" maxWidth={1000}>
        <button onClick={closeModal} className="close_btn">
          Close
        </button>
        <Box className="page-container table-phases" mt={4}>
          <Box display={"flex"} mb={2}>
            <h4 className={styles.history_heding}>Course History</h4>
            <span className={styles.image_wrap}>
              <ImageView
                imageName={row && row.profile_image}
                name={row && row.name}
              />
              {row && toTitleCase(`${row.name}`)}
            </span>
          </Box>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.USER_MODULE_STUDENTS_ENROLL_HISTORY + row.id}
            actionColumns={false}
            module="manage-students"
            pagination={false}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default EnrollHistroyModal;
