import React, { useState, useEffect } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
import styles from "./style.module.css";
import ImageView from "../../../components/imageShow";
import Moment from "react-moment";
import CSVDownloadButton from "../../../components/downloadCSV";
import { useParams } from "react-router-dom";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { EditWorkShop } from "../manage-workshops/edit";
import { EditWorkshopAttend } from "../manage-workshop-attendence/edit";
import {HasPermission} from '../../../helper/helperFunction'


export default function WorkShopBanner({setReRender}) {
  const params = useParams();
  const [bannerData, setBannerData] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [attendModalOpen, setAttendModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setAttendModalOpen(false);
  };

  const [formSuccess, setFormSuccess] = useState(false);
  useEffect(() => {
    setModalOpen(false);
    setAttendModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);


  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get(
        resolveUrl(AdminApi.WORKSHOP_BANNER, params.id ?? "")
      );
      setBannerData(res.data.data);
    };
    fetchData();
  }, [params.id, formSuccess]);




function calculateNewTime(time, duration) {
  const [hours, minutes] = time.split(":").map(Number);
  let totalMinutes = hours * 60 + minutes;

  const durationInMinutes = parseInt(duration);
  totalMinutes += durationInMinutes * 60;

  const newHours = Math.floor(totalMinutes / 60) % 12;
  const newMinutes = totalMinutes % 60;
  const newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')} ${totalMinutes < 720 ? 'AM' : 'PM'}`;

  return newTime;
}




  return (
    <>
      <Grid container marginTop={12} alignItems={"center"}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" component="h1" className={styles.heading}>
              Workshop Reports
            </Typography>
          </Grid>
          <Grid item xs={6} className={styles.downloadBtn}>
            <CSVDownloadButton
              apiEndpoint={resolveUrl(AdminApi.WORKSHOP_DETAIL_CSV,params.id)}
              fileName={"Workshop Detail"}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.workShopInfo}>
          <Grid item xs={12}>
            <Grid
              container
              marginTop={1}
              className={styles.workShopDetailHeader}
            >
              <Grid item xs={6} className={styles.workShopDetailHeaderleft}>
                <Typography
                  variant="body1"
                  component="p"
                  className={styles.subHeading}
                >
                  {bannerData && bannerData.title}
                </Typography>
              </Grid>
              <Grid item xs={6} className={styles.workShopbtnsWrapper}>
              <HasPermission permission="manage-work-shop.edit"> 
                <Box className={styles.EditButton}>
                  <Button onClick={() => setModalOpen(true)}>
                    Edit Workshop
                  </Button>
                </Box>
                </HasPermission>
                <HasPermission permission="manage-work-shop-attendance.create"> 
                <Box className={styles.AttendanceButton}>
                  <Button onClick={() => setAttendModalOpen(true)}>
                    Add Attendance
                  </Button>
                </Box>
                </HasPermission>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                item
                container
                className={styles.infoWrap}
                justifyContent={"space-between"}
              >
                <Box className={styles.workShopTrainer}>
                  <ImageView
                    width="35px"
                    height="35px"
                    marginRight="10px"
                    imageName={bannerData && bannerData.profile_image}
                    name={bannerData && bannerData.teacher}
                  />
                  <Typography
                    className={styles.workShopInfoValue}
                    variant="body2"
                    component="span"
                  >
                    {bannerData && bannerData.teacher}
                  </Typography>
                </Box>
                <Box className={styles.borderLeft}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoSubHeading}
                  >
                    Cohorts(s)
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoValue}
                  >
                    {bannerData &&
                      bannerData.workshop_sections.map((item, index) => {
                        return (
                          <>
                            <span key={index}> {item.cohort_name}, </span>
                          </>
                        );
                      })}
                  </Typography>
                </Box>
                <Box className={styles.borderLeft}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoSubHeading}
                  >
                    Section (s)
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoValue}
                  >
                    {bannerData &&
                      bannerData.workshop_sections &&
                      bannerData.workshop_sections.map((section) => {
                        return section.sections.map((item, index) => {
                          return <span key={index}> {item.section_name}, </span>;
                        });
                      })}
                  </Typography>
                </Box>
                <Box className={styles.borderLeft}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoSubHeading}
                  >
                    Duration
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoValue}
                  >
                    {bannerData && bannerData.duration} hrs
                  </Typography>
                </Box>
                <Box className={styles.borderLeft}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoSubHeading}
                  >
                    Date Time
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoValue}
                  >
                    <Moment format="DD/MM/YYYY ">
                      {bannerData && bannerData.date}
                    </Moment>{" "}
                    {bannerData &&
                    <Moment format="hh:mm A" parse="HH:mm">
                        {bannerData && bannerData.time}
                      </Moment> } to {bannerData && calculateNewTime(bannerData.time, bannerData.duration)}
                    
                  </Typography>
                </Box>
                <Box className={styles.borderLeft}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoSubHeading}
                  >
                    Students
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.workShopInfoValue}
                  >
                    {bannerData && bannerData.student}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EditWorkShop
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setModalOpen={setModalOpen}
        setFormSuccess={setFormSuccess}
      />

      <EditWorkshopAttend
        attendModalOpen={attendModalOpen}
        setAttendModalOpen={setAttendModalOpen}
        handleCloseModal={handleCloseModal}
        setFormSuccess={setFormSuccess}
        date={bannerData && bannerData.date}
        time={bannerData && bannerData.time}
      />
    </>
  );
}
