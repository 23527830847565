import { Typography } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useNavigate, useParams } from "react-router-dom";
import { api, resolveUrl } from "../../../config/apis";
import ImageView from "../../../components/imageShow";
import Moment from "react-moment";

export const OrgFundHistoryGrid = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
      minWidth: "120px",
    },
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.student_name} />
          {toTitleCase(`${row.student_name ?? ""}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "200px",
      selector: "student_name",
    },
    {
      selector: (row) => row.section_name,
      name: "Section",
      sortable: true,
      minWidth: "200px",
    },

    {
      selector: (row) => row.fund_type || row.type,
      name: "Fund Type",
      sortable: true,
      minWidth: "150px",
    },
    {
      cell: (row) => (
        <Moment format="DD/MM/YYYY  hh:mmA">{row.datetime}</Moment>
      ),
      name: "Date / Time",
      minWidth: "180px",
      sortable: true,
      selector: "datetime",
    },

    {
      cell: (row) => (
        <Typography variant="body1" fontWeight={"700"}>
          {row.amount} {row.currency_symbol}
        </Typography>
      ),
      name: "Amount",
      sortable: true,
      minWidth: "180px",
      selector: "amount",
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/transactions/view/${id}`);
  };

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.TRANSACTIONS_DELETE, id));
  };

  return (
    <>
      <div className="page-container table-phases" style={{ width: "100%" }}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.ORGANIZATION_FUNDS_HISTORY_PAGINATION,
            params.id
          )}
          onView={onView}
          onDelete={onDelete}
          filters={props.filters ?? ""}
          sortOrder={props.currentValue ?? "asc"}
          actions={{
            view: true,
            edit: false,
            delete: props.delete ?? true,
          }}
          module="manage-transactions"
        />
      </div>
    </>
  );
};
