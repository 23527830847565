import { Typography, capitalize } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useState, useEffect } from "react";
import { api, resolveUrl } from "../../../config/apis";
import ImageView from "../../../components/imageShow";
import Moment from "react-moment";
import { EditSupportTicket } from "./edit";
import { useLocation } from "react-router-dom";
import { ViewTicket } from "./ticket-view";


export const SupportTicketsGrid = (props) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const queryParamsId = queryParams.get("id");

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
      width: "60px",
    },
    {
      selector: (row) => row.title,
      name: "Title",
      sortable: true,
      width: "200px",
    },
    {
      cell: (row) => (
        <>
          <ImageView
            imageName={row.profile_image_path}
            name={row.assigned_by}
          />
          {toTitleCase(`${row.assigned_by ?? ""}`)}
        </>
      ),
      name: "Assign by",
      selector: "assigned_by",
      sortable: true,
      width: "300px",
    },
    {
      selector: (row) => row.role,
      name: "Role",
      sortable: true,
      width: "150px",
    },
    {
      cell: (row) => (
        <>
          <ImageView
            imageName={row.assigned_to_profile_image}
            name={row.assigned_to}
          />
          {row.assigned_to && toTitleCase(`${row.assigned_to ?? ""}`)}
        </>
      ),
      name: "Assigned To",
      selector: "assigned_to",
      sortable: true,
      width: "300px",
    },
    {
      selector: (row) => row.assigned_to_location,
      name: "Location",
      sortable: true,
      width: "200px",
    },
    {
      selector: (row) => row.status && toTitleCase(row.status),
      name: "Status",
      sortable: true,
      width: "150px",
    },
    
    {
      cell: (row) => (
        <Moment format="DD/MM/YYYY  hh:mmA">{row.date_created}</Moment>
      ),
      selector: "date_created",
      name: "Date Created",
      width: "180px",
      sortable: true,
    },
    {
      cell: (row) => (
        <Moment format="DD/MM/YYYY  hh:mmA">{row.due_date}</Moment>
      ),
      selector: "due_date",
      name: "Due Date",
      width: "180px",
      sortable: true,
    },

  
    {
      cell: (row) => (
        <>
         <div className="support-tickets-disc" style={{
          overflow: "hidden",
          width: "280px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
         }} dangerouslySetInnerHTML={{ __html: row.description }}></div>
        </>
      ),
      selector: "description",
      name: "Description",
      sortable: true,
      width: "300px",
    },
  ];

  const [viewModalOpen, setViewModalOpen] = useState(false); // Separate state for view modal
  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [viewId, setViewId] = useState("");
  

  // Function to handle viewing a ticket
  const onView = async (id) => {
    if (!id) return;
    setViewId(id);
    setViewModalOpen(true); // Open view modal
  };

  // Function to handle editing a ticket
  const onEdit = async (id) => {
    if (id) {
      setViewId(id);
      props.setEditModalOpen(true); // Open edit modal
    }
  };

  const handleCloseEditModal = () => {
    props.setEditModalOpen(false);
    setViewId("");
  };


  const handleCloseViewModal = () => {
    setViewModalOpen(false);
  };

  useEffect(() => {
    if (queryParamsId) {
      onView(queryParamsId); // Call onView with the current queryParamsId
    }
  }, [queryParamsId]);

  useEffect(() => {
    if (!queryParamsId) {
      setReRender(formSuccess);
      props.setEditModalOpen(false);
      setViewModalOpen(false); // Close both modals when the form succeeds
    }
  }, [formSuccess]);

  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.SUPPORT_TICKET_LIST}
          onView={onView}
          onEdit={onEdit}
          filters={props.filters ?? ""}
          sortOrder={props.currentValue ?? "asc"}
          actions={{
            view: true,
            edit: true,
            delete: false,
          }}
          module="manage-support-ticket"
          refreshHandle={reRender}
        />
      </div>
      <EditSupportTicket
        modalOpen={props.editModalOpen}
        handleCloseModal={handleCloseEditModal}
        setModalOpen={props.setEditModalOpen}
        setFormSuccess={setFormSuccess}
        editId={viewId}
        setViewId={setViewId}
      />
      <ViewTicket
        modalOpen={viewModalOpen}
        handleCloseModal={handleCloseViewModal}
        setModalOpen={setViewModalOpen}
        setFormSuccess={setFormSuccess}
        viewId={viewId}
      />
    </>
  );
};

