import { Box, Grid } from "@mui/material";
import styles from "./style.module.css";
import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StudentApi from "../../../config/apis/student";
import { api } from "../../../config/apis";
import Moment from "react-moment";

export default function Assignments() {
  const [assignments, setAssignments] = useState([]);
  const [weeklyPercent, setWeeklyPercent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(StudentApi.ASSIGNMENT);
        const assessments =
          res &&
          res.data.data.filter(
            (assessment) => assessment.assessment_type !== "Weekly Assignment"
          );
        const weeklyAssessments =
          res &&
          res.data.data.filter(
            (assessment) => assessment.assessment_type == "Weekly Assignment"
          );

        setWeeklyPercent(weeklyAssessments);
        setAssignments(assessments ?? []);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box className={`container ${styles.assignments_wrap}`}>
        <Box class="course-content">
          <h1 class="main-heading">My Assignments</h1>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={styles.Weekly_Assignment_title}>
              Weekly Assignment Percentage :{" "}
              <span>
                {weeklyPercent && weeklyPercent.length > 0 ? (
                  <span>
                    {weeklyPercent[0].weekly_percentage < 50 ? (
                      <span style={{ color: "red" }}>
                        {weeklyPercent[0].weekly_percentage.toFixed(2) + "%"}
                      </span>
                    ) : (
                      <span style={{ color: "#00CF53" }}>
                        {weeklyPercent[0].weekly_percentage.toFixed(2) + "%"}
                      </span>
                    )}
                  </span>
                ) : (
                  "N/A"
                )}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {assignments &&
              assignments.map((item, index) => {
                return (
                  <Accordion key={index} className={styles.accordion_wrap}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box>
                        <Typography className={styles.Assgniment_Title}>
                          {item.assignment_title ?? ""}
                        </Typography>
                        <Typography className={styles.Assgniment_date}>
                          {item.assignment_start_date && (
                            <Moment format="DD/MM/YYYY">
                              {item.assignment_start_date}
                            </Moment>
                          )}
                        </Typography>
                      </Box>
                      <span className={`${styles.marking} ${item.status}`}>
                        {item.status}
                      </span>
                      <Box paddingRight={10} paddingLeft={10}>
                        <Typography className={styles.Assgniment_percentage}>
                          {item.assessment_percentage
                            ? item.assessment_percentage.toFixed(2) + "%"
                            : "-"}
                        </Typography>
                        <Typography className={styles.Assgniment_statuse}>
                          {item.assessment_percentage ? (
                            <span>
                              {item.assessment_percentage &&
                              item.assessment_percentage < 50 ? (
                                <span style={{ color: "red" }}>Fail</span>
                              ) : (
                                <span style={{ color: "#00CF53" }}>Pass</span>
                              )}
                            </span>
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow
                              className={styles.table_row}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>Course Name</TableCell>
                              <TableCell>Started on</TableCell>
                              <TableCell>Ended on</TableCell>
                              <TableCell>Marks Obtained</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              className={styles.table_row_2}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="">
                                {item.course_title}
                              </TableCell>
                              <TableCell align="">
                                {item.assignment_start_date && (
                                  <Moment format="DD/MM/YYYY">
                                    {item.assignment_start_date}
                                  </Moment>
                                )}
                              </TableCell>
                              <TableCell align="">
                                {item.assignment_end_date && (
                                  <Moment format="DD/MM/YYYY">
                                    {item.assignment_end_date}
                                  </Moment>
                                )}
                              </TableCell>
                              <TableCell align="">
                                {(item.marks ? item.marks : "- ") +
                                  "/" +
                                  item.total_marks}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box className={styles.remarks_wrap}>
                        <Typography className={styles.Remarks_title}>
                          Remarks
                        </Typography>
                        <Typography className={styles.remarks_content}>
                          {item.feedback}
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
