import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Button,
  Select,
  Grid,
  Checkbox,
  MenuItem,
  TextField,
  FormHelperText,
  InputAdornment,
  Typography,
} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import BootstrapInput from "../../../layout/admin/partial/BootstrapInput";
import Form from "../../../components/forms/Forms";
import styles from "./manage-section.module.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SelectOptions from "../../../components/Select";
import * as yup from "yup";
// import { setHours, startOfDay } from "date-fns";
import Avatar from "@mui/material/Avatar";
import ImageView from "../../../components/imageShow";
import { formatDateYYMMDD } from "../../../helper/helperFunction";
import EditIcon from "@mui/icons-material/Edit";

// SECTION MODULE

export default function SectionAdd({}) {
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the 'id' parameter
  const id = queryParams.get("id");

  const courseId = parseInt(id);

  const [formData, setFormData] = useState({
    name: "",
    cohort_id: null,
    shift_id: null,
    teacher_id: null,
    course_id: courseId,
    start_date: "",
    end_date: "",
    selected_day: "",
    scaled_by_max: false,
    AssessmentData: [
      {
        title: "Weekly Assignments weightage",
        weightage: null,
        assessment_type: "",
      },
      {
        title: "Assignments weightage",
        weightage: null,
        assessment_type: "",
      },
    ],
    scoring_criteria: [
      {
        title: "Following Best Practices",
        score: "",
      },
      {
        title: "Achieving Target Output",
        score: "",
      },
      {
        title: "Class Performance",
        score: "",
      },
      {
        title: "Quality of work",
        score: "",
      },
    ],
  });

  const [title, setTitle] = useState("");
  const [cohort, setCohort] = useState([]);
  const [shift, setShift] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const [assessmentWeightage, setAssessmentWeightage] = useState(0);

  const [validationMessage, setValidationMessage] = useState("");

  const addField = () => {
    if (title.trim() !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        AssessmentData: [
          ...prevFormData.AssessmentData,
          { title: title, weightage: null, assessment_type: "" },
        ],
      }));
      setTitle("");
      setValidationMessage("");
    } else {
      setValidationMessage("Title cannot be empty");
    }
  };

  const removeField = (index) => {
    const updatedData = [...formData.AssessmentData];
    updatedData.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      AssessmentData: updatedData,
    }));
  };

  const handleFieldChange = (index, key, value) => {
    const updatedData = [...formData.AssessmentData];
    if (!isNaN(value)) {
      updatedData[index][key] = parseInt(value);
    } else {
      updatedData[index][key] = null; // Set it to null if not a valid integer
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      AssessmentData: updatedData,
    }));
  };
  const handleFieldChangeType = (index, key, value) => {
    const updatedData = [...formData.AssessmentData];
    if (value) {
      updatedData[index][key] = value;
    } else {
      updatedData[index][key] = null; // Set it to null if not a valid integer
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      AssessmentData: updatedData,
    }));
  };

  // const handleFormSuccess = async (response) => {
  //   if (response) {
  //     setFormSuccess((prevState) => !prevState);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.SECTION_MODULE_VIEW, params.id)
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: res.data.section.name,
          teacher_id: res.data.section.teacher_id,
          shift_id: res.data.section.shift_id,
          cohort_id: res.data.section.cohort_id,
          course_id: res.data.section.course_id,
          selected_day: res.data.section.selected_day,
          scaled_by_max: res.data.section.scaled_by_max,
          //   phase_id: res.data.data.phase_id,
          start_date: moment(res.data.section.start_date).format("YYYY-MM-DD"),
          end_date: moment(res.data.section.end_date).format("YYYY-MM-DD"),
          AssessmentData: res.data.assessment_data.map((item) => ({
            id: item.id,
            title: item.title,
            assessment_type: item.assessment_type,
            weightage: item.weightage,
          })),

          scoring_criteria: res.data.scoring_criteria.map((item) => ({
            title: item.criteria,
            // assessment_type: item.assessment_type,
            score: item.score,
          })),
        }));
      } catch (error) {
        // Error handling
      }
    };

    const fetchChort = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.COHORTS));
        setCohort(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    const fetchShift = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.SHIFTS));
        setShift(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    const fetchTeacher = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.TEACHERS));
        setTeacher(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    fetchData();
    fetchChort();
    fetchShift();
    fetchTeacher();
  }, [params.id]);

  const renderSelectOptions = () => {
    const timeOptions = [
      "Weekly Assignment",
      "Final Viva",
      "Final Project",
      "Assignment-1",
      "Assignment-2",
    ];

    return timeOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ));
  };

  useEffect(() => {
    const totalWeightage = formData.AssessmentData.reduce(
      (sum, field) => sum + (field.weightage || 0),
      0
    );
    setAssessmentWeightage(totalWeightage + 15);
  }, [formData.AssessmentData]);

  const [assessmentScore, setAssessmentScore] = useState(0);

  useEffect(() => {
    if (formData && formData.scoring_criteria) {
      const totalScore = formData.scoring_criteria.reduce(
        (sum, field) => sum + (parseFloat(field.score) || 0), // Parse score as a float
        0
      );
      setAssessmentScore(totalScore);
    }
  }, [formData]);

  const [totalWeightage, setTotalWeightage] = useState(0);

  const [assessment, setAssessment] = useState();

  const cohortID = formData.cohort_id;

  const [scoringTitle, setScoringTitle] = useState("");

  const addScoringField = () => {
    if (scoringTitle.trim() !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        scoring_criteria: [
          ...prevFormData.scoring_criteria,
          { title: scoringTitle, score: "" },
        ],
      }));
      setScoringTitle("");
      setValidationMessage("");
    } else {
      setValidationMessage("Title cannot be empty");
    }
  };

  const removeScoringField = (index) => {
    const updatedData = [...formData.scoring_criteria];
    updatedData.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      scoring_criteria: updatedData,
    }));
  };

  const handleScoringFieldChange = (index, key, value) => {
    const updatedData = [...formData.scoring_criteria];
    updatedData[index][key] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      scoring_criteria: updatedData,
    }));
  };

  const renderAssessmentTypeOptions = () => {
    const assessmentTypes = [
      "Assignment",
      "Attendance",
      "WeeklyAssignment",
      "WorkshopAttendance",
      "Viva",
      "FinalProject",
    ];

    return assessmentTypes.map((type) => (
      <MenuItem key={type} value={type}>
        {type}
      </MenuItem>
    ));
  };

  useEffect(() => {
    const isEditPage = window.location.pathname.includes("admin/section/edit");

    if (isEditPage) {
      const fetchAssessmentWeightageData = async () => {
        try {
          const res = await api.get(
            resolveUrl(AdminApi.COHORT_ASSESSMENT_WEIGHTAGE, cohortID)
          );
          const AssessmentType = res.data.data.map((item) => ({
            title: item.title,
            weightage: item.weightage,
            assessment_type: item.assessment_type,
          }));
          const updatedFormData = {
            ...formData,
            AssessmentData: AssessmentType,
          };
          setFormData(updatedFormData);
        } catch (error) {
          // Error handling
          console.error(error);
        }
      };
      if (!params.id) {
        fetchAssessmentWeightageData();
      }
    }
  }, [cohortID]);

  useEffect(() => {
    const fetchPrefilledData = async () => {
      try {
        const response = await api.get(
          resolveUrl(AdminApi.COURSE_SCORING_CRITERIA, id)
        );
        const scoringCriteria = response.data.data.map((item) => ({
          title: item.title,
          score: item.weightage,
        }));
        const updatedFormData = {
          ...formData,
          scoring_criteria: scoringCriteria,
        };
        setFormData(updatedFormData);
      } catch (error) {
        console.error("Error fetching pre-filled data:", error);
      }
    };
    if (!params.id) {
      fetchPrefilledData();
    }
    // }
  }, [id]);

  const daysOfWeek = [
    { id: "monday", options: "Monday" },
    { id: "tuesday", options: "Tuesday" },
    { id: "wednesday", options: "Wednesday" },
    { id: "thursday", options: "Thursday" },
    { id: "friday", options: "Friday" },
    { id: "saturday", options: "Saturday" },
    { id: "sunday", options: "Sunday" },
  ];
  const [errors, setErrors] = useState({});
  const handleUpdate = (name, value) => {
    setFormData((formData) => ({ ...formData, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const View = () => {
    navigate(`/admin/courses/view/${id}`);
  };

  const schema = yup.object().shape({
    name: yup.string().required("Fields are required"),
    cohort_id: yup.string().required("Fields are required"),
    shift_id: yup.string().required("Fields are required"),
    teacher_id: yup.string().required("Fields are required"),
    start_date: yup.string().required("Fields are required"),
    end_date: yup.string().required("Fields are required"),
    selected_day: yup.string().required("Fields are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false }); // Validate with Yup schema
      const newErrors = {};

      // Custom validation for assessmentWeightage
      if (assessmentWeightage !== 100) {
        newErrors["assessmentWeightage"] =
          "Assessment weightage cannot be greater than 100% and less than 100%";
      }

      if (assessmentScore !== 100) {
        newErrors["assessmentScore"] =
          "Assessment weightage cannot be greater than 100% and less than 100%";
      }

      setErrors(newErrors); // Set Yup validation errors in the state

      // Check if there are any errors (Yup or custom)
      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors); // Set Yup validation errors in the state
      return false; // Validation failed
    }
  };
  const today = new Date();

  const handleOnChecked = (event) => {
    setFormData({
      ...formData,
      scaled_by_max: event.target.checked,
    });
  };
  const isEditPage = window.location.pathname.includes(
    `admin/section/edit/${params.id}`
  );

  const updatedFormData = {
    ...formData,
    scoring_criteria: formData.scoring_criteria.map((item) => ({
      ...item,
      score: Number(item.score),
    })),
  };

  const updateAssessmentTitle = (index, newTitle) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      AssessmentData: prevFormData.AssessmentData.map((assessment, i) =>
        i === index ? { ...assessment, title: newTitle } : assessment
      ),
    }));
  };

  const [editIndex, setEditIndex] = useState(null);

  const editTitle = (index) => {
    setEditIndex(index);
  };
  const handleTitleUpdate = () => {
    setEditIndex(null);
  };

  return (
    <Box className="page-container container Add_Section">
      <h1>{params.id ? "Edit Section" : "Add Section"}</h1>
      <Form
        // onSuccess={`/admin/courses/view/${id??params.id}`}
        successPath={`/admin/courses/view/${id ?? formData.course_id}`}
        submitUrl={AdminApi.SECTION_MODULE_SAVE}
        formData={updatedFormData}
        validate={customValidation}
        // onEdit={onEdit}
        editId={params.id}
      >
        <Box className={styles.flexform}>
          <Box className={styles.stepone}>
            <Box className="field" mb="20px">
              <h2 className={styles.topHeading}>Section Details</h2>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#001C28",
                    fontSize: "14px !important",
                    fontWeight: "700",
                  }}
                >
                  Section Title*
                </InputLabel>
                <BootstrapInput
                  required
                  id="outlined-required"
                  fullWidth
                  name="name"
                  // disabled={isEditPage}
                  error={errors.name !== undefined}
                  value={formData.name}
                  onChange={(event) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      ...prevFormData,
                      name: event.target.value,
                    }))
                  }
                  size="small"
                />
                {errors.name && (
                  <FormHelperText error>{errors.name}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box className="field select_field_with_image" mb="20px">
              <Grid item xs={12} sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel
                    sx={{ textAlign: "left", fontSize: "14px !important" }}
                  >
                    Assign Teacher
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    sx={{ maxWidth: "100%" }}
                    select
                    required
                    id="outlined-required"
                    fullWidth
                    name="teacher_id"
                    value={formData.teacher_id}
                    error={errors.teacher_id !== undefined}
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        ...prevFormData,
                        teacher_id: event.target.value,
                      }))
                    }
                    size="small"
                  >
                    {errors.teacher_id && (
                      <FormHelperText error>{errors.teacher_id}</FormHelperText>
                    )}
                    {teacher.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        <ImageView
                          imageName={teacher.profile_image ?? ""}
                          name={teacher.name ?? ""}
                          width="35px"
                          height="35px"
                        />
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box className="field" mb="20px">
              <Grid item xs={12} sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel
                    sx={{ textAlign: "left", fontSize: "14px !important" }}
                  >
                    Shift*
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    sx={{ maxWidth: "100%" }}
                    // disabled={isEditPage}
                    select
                    required
                    id="outlined-required"
                    fullWidth
                    name="shift_id"
                    error={errors.shift_id !== undefined}
                    value={formData.shift_id}
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        ...prevFormData,
                        shift_id: event.target.value,
                      }))
                    }
                    size="small"
                  >
                    {errors.shift_id && (
                      <FormHelperText error>{errors.shift_id}</FormHelperText>
                    )}
                    {shift &&
                      shift.map((shift) => (
                        <MenuItem key={shift.id} value={shift.id}>
                          {shift.title}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box className="field" mb="20px">
              <Grid item xs={12} sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel
                    sx={{ textAlign: "left", fontSize: "14px !important" }}
                  >
                    Cohort*
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    sx={{ maxWidth: "100%" }}
                    select
                    disabled={isEditPage}
                    required
                    id="outlined-required"
                    fullWidth
                    name="cohort_id"
                    error={errors.cohort_id !== undefined}
                    value={formData.cohort_id}
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        ...prevFormData,
                        cohort_id: event.target.value,
                      }))
                    }
                    size="small"
                  >
                    {errors.cohort_id && (
                      <FormHelperText error>{errors.cohort_id}</FormHelperText>
                    )}
                    {cohort.map((cohort) => (
                      <MenuItem key={cohort.id} value={cohort.id}>
                        {cohort.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box className={styles.fieldflex}>
              <Box
                className="field-set"
                mb="20px"
                mr="25px"
                sx={{ width: "100%" }}
              >
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{
                      color: "#001C28",
                      fontSize: "14px !important",
                      fontWeight: "700 !important",
                    }}
                  >
                    Started On*
                  </InputLabel>
                  <DatePicker
                    selected={
                      formData.start_date ? new Date(formData.start_date) : null
                    }
                    dateFormat="yyyy-MM-dd"
                    required
                    id="outlined-required"
                    fullWidth
                    name="start_date"
                    // minDate={setHours(startOfDay(new Date()), 0)} // Set the min date to the current date
                    maxDate={null} // Allow future dates
                    error={errors.start_date !== undefined}
                    value={formData.start_date ? formData.start_date : ""}
                    onChange={(date) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        start_date: date ? formatDateYYMMDD(date) : null,
                      }))
                    }
                    size="small"
                  />
                  {errors.start_date && (
                    <FormHelperText error>{errors.start_date}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className="field-set" mb="20px" sx={{ width: "100%" }}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{
                      color: "#001C28",
                      fontSize: "14px !important",
                      fontWeight: "700",
                    }}
                  >
                    Ended On*
                  </InputLabel>
                  <DatePicker
                    selected={
                      formData.end_date ? new Date(formData.end_date) : null
                    }
                    dateFormat="yyyy-MM-dd"
                    required
                    id="outlined-required"
                    fullWidth
                    name="end_date"
                    // minDate={setHours(startOfDay(new Date()), 0)} // Set the min date to the current date
                    maxDate={null} // Allow future dates
                    error={errors.end_date !== undefined}
                    value={formData.end_date ? formData.end_date : ""}
                    onChange={(date) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        end_date: date ? formatDateYYMMDD(date) : null,
                      }))
                    }
                    size="small"
                  />
                  {errors.end_date && (
                    <FormHelperText error>{errors.end_date}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
            <Box className="field" mb="20px">
              <Grid
                item
                xs={12}
                sx={{ mb: 3, alignItems: "center" }}
                className="for-errors"
              >
                <Grid item xs={2}>
                  <InputLabel
                    sx={{ textAlign: "left", fontSize: "14px !important" }}
                  >
                    Weekly Assignment Day*
                  </InputLabel>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <SelectOptions
                    sx={{ maxWidth: "100%", width: "50%" }}
                    options={daysOfWeek}
                    formData={formData}
                    onUpdate={handleUpdate}
                    disabled={isEditPage}
                    labelKey="options"
                    valueKey="id"
                    name="selected_day"
                    errorText={errors.selected_day}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "80%",
                      paddingLeft: "20px",
                    }}
                    className="sectionAdd"
                  >
                    <InputLabel
                      className="is_admin_label"
                      shrink
                      htmlFor="bootstrap-input"
                      sx={{
                        position: "static",
                        color: "#001C28",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      Scaled by Max
                    </InputLabel>
                    <Box className="role_swith_img">
                      <Checkbox
                        required
                        fullWidth
                        name="scaled_by_max"
                        onChange={handleOnChecked}
                        size="small"
                        disabled={isEditPage}
                        checked={formData.scaled_by_max}
                        error={errors.scaled_by_max !== undefined}
                        inputProps={{ "aria-label": "scaled by max checkbox" }}
                      />
                      {/* {errors.is_support_admin && (
                <FormHelperText error>{errors.is_support_admin}</FormHelperText>
              )} */}
                      {/* {formData.scaled_by_max ? (
                        <img src={Flag} alt="Flag" width={"20px"} />
                      ) : (
                        <img src={FlagFalse} alt="Flag" width={"20px"} />
                      )} */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div className={styles.steptwo}>
            {validationMessage && (
              <FormHelperText error sx={{ marginLeft: 4 }}>
                {validationMessage}
              </FormHelperText>
            )}
            <h2 className={styles.mrheading}>
              Scoring Criteria ({assessmentScore}%)
            </h2>
            <label>
              <h5 className={styles.margin}>
                Add Criteria ({assessmentScore}%)*
              </h5>
            </label>
            <span style={{ display: "flex", alignItems: "Start" }}>
              <TextField
                required
                className={styles.seperatemargin}
                id="outlined-required"
                fullWidth
                name="scoringTitle"
                error={validationMessage !== ""}
                value={scoringTitle}
                onChange={(event) => setScoringTitle(event.target.value)}
                size="small"
                disabled={!!params.id}
              />
              <Button
                variant="contained"
                color="primary"
                className={styles.plusbtn}
                onClick={addScoringField}
                disabled={!!params.id}
              >
                +
              </Button>
            </span>
            {formData.scoring_criteria.map((field, index) => (
              <div key={index}>
                <h5 className={styles.margin}>{field.title}*</h5>
                <Box className={styles.flexformfield}>
                  <TextField
                    required
                    className={styles.margin}
                    id="outlined-required"
                    fullWidth
                    name="score"
                    value={field.score}
                    disabled={!!params.id}
                    onChange={(event) =>
                      handleScoringFieldChange(
                        index,
                        "score",
                        event.target.value
                      )
                    }
                    size="small"
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    className={styles.minusbtn}
                    disabled={!!params.id}
                    onClick={() => removeScoringField(index)}
                  >
                    -
                  </Button>
                </Box>
              </div>
            ))}
            {errors.assessmentScore && (
              <FormHelperText error>{errors.assessmentScore}</FormHelperText>
            )}
          </div>
          <div className={`${styles.steptwo} ${styles.stepthree}`}>
            {validationMessage && (
              <FormHelperText error sx={{ marginLeft: 4 }}>
                {validationMessage}
              </FormHelperText>
            )}
            <h2 className={styles.mrheading}>
              Assessment Weightage ({assessmentWeightage}%)
            </h2>
            <label>
              <h5 className={styles.margin}>
                Add Weightage ({assessmentWeightage}%)*
              </h5>
            </label>

            <span style={{ display: "flex", alignItems: "Start" }}>
              <TextField
                fullWidth
                className={styles.seperatemargin}
                name="title"
                variant="outlined"
                margin="normal"
                onChange={(event) => setTitle(event.target.value)}
                size="small"
                errors={errors}
                disabled={!!params.id}
              />
              <Button
                variant="contained"
                color="primary"
                className={styles.plusbtn}
                onClick={addField}
                disabled={!!params.id}
              >
                +
              </Button>
            </span>

            <div>
              <h5 className={styles.margin}>Attendance weightage* </h5>
              <Box className={styles.flexformfield}>
                <TextField
                  sx={{ backgroundColor: "#F4F4F4" }}
                  className={styles.margin}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={12}
                  disabled
                />

                <Select
                  sx={{ backgroundColor: "#F4F4F4" }}
                  value={"Attendance weightage"}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled
                >
                  <MenuItem value={"Attendance weightage"} selected>
                    Attendance weightage
                  </MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.minusbtn}
                  style={{ opacity: "0" }}
                >
                  -
                </Button>
              </Box>
            </div>
            <div>
              <h5 className={styles.margin}>Workshop Attendance weightage*</h5>
              <Box className={styles.flexformfield}>
                <TextField
                  className={styles.margin}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={3}
                  disabled
                  sx={{ backgroundColor: "#F4F4F4" }}
                />

                <Select
                  sx={{ backgroundColor: "#F4F4F4" }}
                  value={"Workshop Attendance"}
                  variant="outlined"
                  disabled
                  fullWidth
                  margin="normal"
                  size="small"
                >
                  <MenuItem value={"Workshop Attendance"} selected>
                    Workshop Attendance
                  </MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.minusbtn}
                  style={{ opacity: "0" }}
                >
                  -
                </Button>
              </Box>
            </div>

            {formData.AssessmentData.map((field, index) => (
              <div key={index}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  {editIndex === index ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={field.title}
                          onChange={(e) =>
                            updateAssessmentTitle(index, e.target.value)
                          }
                          sx={{ marginRight: 2, marginLeft: "30px" }}
                        />
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "#c33f28!important" }}
                          onClick={() => handleTitleUpdate(index)}
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <h5 className={styles.margin}>{field.title}*</h5>
                  )}
                  <Box>
                    {/* <Button
                      sx={{ marginRight: 1 }}
                      variant="contained"
                      color="secondary"
                      className={styles.minusbtn}
                      onClick={() => editTitle(index)}
                    >
                      <EditIcon fontSize="10px" />
                    </Button> */}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={styles.minusbtn}
                      onClick={() => removeField(index)}
                      disabled={!!params.id}
                    >
                      -
                    </Button>
                  </Box>
                </Box>

                <Box className={styles.flexformfield}>
                  <TextField
                    type="number"
                    className={styles.margin}
                    value={field.weightage}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        "weightage",
                        parseInt(e.target.value)
                      )
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    errorText={errors}
                    disabled={!!params.id}
                  />
                  <Select
                    value={field.assessment_type}
                    disabled={!!params.id}
                    onChange={(e) =>
                      handleFieldChangeType(
                        index,
                        "assessment_type",
                        e.target.value
                      )
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                  >
                    {renderSelectOptions()}
                  </Select>
                </Box>
              </div>
            ))}
            {errors.assessmentWeightage && (
              <FormHelperText error>
                {errors.assessmentWeightage}
              </FormHelperText>
            )}
          </div>
        </Box>
      </Form>
    </Box>
  );
}
