import {
    Grid,
    InputLabel,
    FormHelperText,
 
  } from "@mui/material";
  import AdminApi from "../../../config/apis/admin";
  import { useState } from "react";
  import GlobalModal from "../../../components/modalBox";
  import Form from "../../../components/forms/Forms";
  import * as yup from "yup";
  import styles from "./manage-support-ticket.module.css";
  import CKEditorComponent from "../../../components/CKEditorComponent";
  import "react-datepicker/dist/react-datepicker.css";
  
  
  export const TicketComment = (props) => {
      
  
    const defaultFormData = {
   
      comment: "",

    };
  
    const [formData, setFormData] = useState(defaultFormData);

    const handleCloseModal = () => {
      props.setModalOpen(false);
      setErrors({});
      setFormData(defaultFormData);
    };
  
    const [errors, setErrors] = useState({});
  
    const schema = yup.object().shape({
        comment: yup.string().required("This field required"),
      
    });
  
    
  
    const customValidation = async () => {
      try {
        await schema.validate(formData, { abortEarly: false });
        setErrors({});
        const newErrors = {};
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
        }));
  
        return Object.keys(newErrors).length === 0;
      } catch (error) {
        const newErrors = {};
  
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
  
        setErrors(newErrors);
        return false;
      }
    };
  
    const handleEditorChange = (event, editor) => {
      const editor_data = editor.getData();
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = editor_data;
      const links = tempDiv.querySelectorAll("a");
      links.forEach((link) => {
        if (link.host !== window.location.host) {
          link.setAttribute("target", "_blank");
        }
      });
  
      const data = tempDiv.innerHTML;
      setFormData((prevFormData) => ({
        ...prevFormData,
        comment: data,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        comment: undefined,
      }));
    };
  
  
    return (
      <>
        <GlobalModal
          open={props.modalOpen}
          onClose={handleCloseModal}
          width={690}
        >
          <Form
            successPath={"/admin/support-tickets"}
            submitUrl={AdminApi.SUPPORT_TICKET_COMMENT}
            formData={formData}
            setFormSuccess={props.setFormSuccess}
            editId={props.ticketId}
            validate={customValidation}
            customClass={styles.tractionForm}
          >
             <span className={styles.comment_tagline}>Add comment to issue</span>
             <h2 className={styles.comment_title}>{props.ticketTitle}</h2>
            <Grid
              container
              justifyContent={"space-between"}
              sx={{ marginBottom: 2 }}
            >  
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item mr={"0px"} xs={2}>
                  <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Comment *
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <CKEditorComponent
                    initialValue={formData.comment}
                    onChange={handleEditorChange}
                    readOnly={!!props.editId}
  
                  />
                  {errors.comment && (
                    <FormHelperText error>
                      {errors.comment}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </GlobalModal>
      </>
    );
  };
  